import React, { useState, useEffect } from 'react'
import { useCollectionSwaps } from '../../../../contexts/CollectionSwapsContext'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Flex, Image, VStack, Text, Button } from '@chakra-ui/react'
import { TraitCollectionData } from '../../../../types/collectionSwapV2'
import PickerNftModal from '../../PickerModal/NftModal'
import { Nft } from '../../../../types/nft'
import CustomTooltip from '../../CustomTooltip'
import BestSwapsTable from './BestSwapsTable'
import TraitPickerTable from './TraitPickerTable'

export const TraitPicker = () => {
    const {
        giveNfts,
        setGiveNfts,
        setGiveCollections,
        traitCollection,
        traitCollectionsData,
        getTraitCollections,
        setGetTraitCollections,
        setIsCreateModalOpen,
        setCreateModalStep,
        collectionsData,
        setGetCollections,
        setModalMode,
        traitPickerMode,
        setTraitPickerMode,
        getTraitCollectionIds,
        setGetTraitCollectionIds,
    } = useCollectionSwaps()

    const handleGiveClick = () => {
        setGiveModalOpen(true)
    }
    const handleGetClick = () => {
        const traitSelectionElement = document.getElementById('trait-selection')
        if (traitSelectionElement) {
            traitSelectionElement.scrollIntoView({ behavior: 'smooth' })
        }
        if (traitPickerMode === "BestSwaps") {
            setTraitPickerMode("TraitPicker")
        }
    }

    const [giveModalOpen, setGiveModalOpen] = useState(false)

    const handlePickNfts = async (nfts: Nft[]) => {
        setGiveNfts(nfts)
        setGiveModalOpen(false)
    }

    useEffect(() => {
        if (traitCollection) setGiveCollections([traitCollection])
    }, [traitCollection])

    useEffect(() => {
        setGetTraitCollections(getTraitCollectionIds)
    }, [getTraitCollectionIds])

    const [canFindSwaps, setCanFindSwaps] = useState(false)

    useEffect(() => {
        setCanFindSwaps(giveNfts.length > 0 && getTraitCollectionIds.length > 0)
        if (!canFindSwaps) {
            setTraitPickerMode("TraitPicker")
        }
    }, [giveNfts, getTraitCollectionIds])


    const selectGetTrait = (traitId: string) => {
        setGetTraitCollectionIds((prev: string[]) => {
            if (prev.includes(traitId)) {
                return prev.filter((id) => id !== traitId)
            } else {
                // Find the matching trait from traitCategories
                const matchingTrait = traitCollectionsData.find((trait) => trait.traitId === traitId)
                if (matchingTrait) {
                    return [...prev, traitId]
                }
                return prev
            }
        })
    }

    const handleSwapAction = () => {
        if (traitPickerMode === "TraitPicker") {
            // Logic to find swaps
            console.log("Finding swaps...")
            setTraitPickerMode("BestSwaps")
        } else {
            setCreateModalStep(0)
            setIsCreateModalOpen(true)
            setModalMode("trait")
        }
    }

    return (
        <VStack>
            <Flex className="w-full" flexDirection={{ base: "column", md: "row" }} justifyContent="space-between">
                <Flex
                    w={{ base: "100%", md: "40%" }}
                    alignItems="center"
                    justifyContent={giveNfts?.length > 0 ? 'space-between' : 'flex-end'}
                    className="flex justify-between items-center p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius new-bg-transparent text-white transition-all duration-200 px-4 py-2" mb={{ base: 4, md: 0 }}
                    mr={{ base: 0, md: 4 }}
                >
                    {giveNfts?.length > 0 && (
                        <Image
                            key={'giveCollection'}
                            src={giveNfts[0].image}
                            alt={giveNfts[0].collection.name}
                            className="potato-radius-sm new-bg-transparent"
                            boxSize="50px"
                            onClick={handleGiveClick}
                        />
                    )}
                    <Button
                        className="px-6 py-2 potato-btn-radius primary-potato-btn"
                        fontSize="large"
                        fontWeight="bold"
                        color="white"
                        onClick={handleGiveClick}
                    >
                        Give
                        <ChevronDownIcon ml={2} />
                    </Button>
                </Flex>

                <Flex w={{ base: "100%", md: "40%" }}
                    alignItems="center"
                    justifyContent="space-between"
                    className="flex justify-between items-center p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius new-bg-transparent text-white transition-all duration-200 px-4 py-3"
                    mb={{ base: 4, md: 0 }}
                    mr={{ base: 0, md: 4 }}>
                    <Flex
                        alignItems="center"
                        overflowX="auto"
                        maxWidth={{ base: "80%", md: "80%" }}
                        pr={2}
                        className="potato-scrollbar"

                    >
                        {getTraitCollections.map((trait, index) => (
                            <React.Fragment key={trait.traitId}>
                                <CustomTooltip label={trait.traitKey + ': ' + trait.traitValue}>
                                    <Image
                                        src={trait.image}
                                        alt={trait.traitKey + ': ' + trait.traitValue}
                                        className="potato-radius-sm new-bg-transparent"
                                        boxSize="50px"
                                        minWidth="50px"
                                        onClick={() => { setTraitPickerMode("TraitPicker"); selectGetTrait(trait.traitId) }}
                                        mr={1}
                                        ml={1}
                                        cursor="pointer"
                                    />
                                </CustomTooltip>
                                {index < getTraitCollections.length - 1 && (
                                    <Text fontSize="sm" fontWeight="bold" color="white" textAlign="center" mr={1}>OR</Text>
                                )}
                            </React.Fragment>
                        ))}
                    </Flex>
                    <Button
                        className="py-2 potato-btn-radius primary-potato-btn"
                        fontSize="large"
                        fontWeight="bold"
                        color="white"
                        onClick={handleGetClick}
                        flexShrink={0}
                    >
                        Get
                        <ChevronDownIcon ml={2} />
                    </Button>
                </Flex>

                <Flex w={{ base: "100%", md: "20%" }} alignItems="center" justifyContent={{ base: "center", md: "flex-end" }}>
                    <Button
                        className="potato-radius px-6 py-2"
                        bg="#FFE0F2"
                        fontSize="large"
                        fontWeight="bold"
                        color="#FF4ADA"
                        onClick={handleSwapAction}
                        height="100%"
                        _hover="#d7b0c7"
                        width="100%"
                        isDisabled={!canFindSwaps && traitPickerMode !== "BestSwaps"}
                    >
                        {traitPickerMode === "BestSwaps" ? "Create Swaps" : "Find Swaps"}
                    </Button>
                </Flex>
            </Flex>

            {traitPickerMode === "TraitPicker" ? (
                <TraitPickerTable
                    traitCollectionsData={traitCollectionsData}
                    selectedTraitIds={getTraitCollectionIds}
                    selectGetTrait={(traitId: string) => { setTraitPickerMode("TraitPicker"); selectGetTrait(traitId) }}
                />
            ) : (
                <BestSwapsTable />
            )}

            <PickerNftModal
                isOpen={giveModalOpen}
                onClose={() => setGiveModalOpen(false)}
                onAction={async (nfts: Nft[]) => {
                    setTraitPickerMode("TraitPicker");
                    handlePickNfts(nfts);
                }}
            />
        </VStack>
    )
}

export default TraitPicker;
