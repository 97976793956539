import { CollectionData, FilterConfig, OnChainSwapData, SwapData } from '../types/collectionSwapV2'

// const getRightCollectionName = (
//   offer: SwapData,
//   filterConfig: FilterConfig,
//   side: 'get' | 'give'
// ) => {
//   const showMakerPerspective =
//     filterConfig.maker === offer.onChainSwap?.data.maker || !offer.isOnChain

//   if (side === 'get') {
//     return showMakerPerspective
//       ? offer.takerCollection.collectionName
//       : offer.makerCollection.collectionName
//   } else {
//     return showMakerPerspective
//       ? offer.makerCollection.collectionName
//       : offer.takerCollection.collectionName
//   }
// }

export function filterCollections(collections: CollectionData[], names?: string[]): CollectionData[] {
  if (!names) return collections
  const lowerCaseNames = names.map((name) => name.toLowerCase())
  return collections.filter((collection) =>
    lowerCaseNames.includes(collection.collectionName.toLowerCase())
  )
}

function filterOffersByCollections(offers: SwapData[], type: 'maker' | 'taker', names: string[]) {
  const lowerCaseNames = names.map((name) => name.toLowerCase())
  return offers.filter((offer) => {
    let collectionName =
      type === 'maker' ? offer.makerCollection.collectionName : offer.takerCollection.collectionName
    return lowerCaseNames.includes(collectionName.toLowerCase())
  })
}

export function filterOffers(
  offers: SwapData[],
  filterConfig?: FilterConfig,
  verbose?: boolean
): SwapData[] {
  // verbose = true
  if (verbose) console.log('filterConfig', filterConfig)
  if (verbose) console.log('offers initial', offers.length)

  if (!filterConfig?.keepNegativeSavings) {
    offers = offers
      .filter((offer) => offer.makerSwapSavings.savings >= 0)
      .filter((offer) => offer.takerSwapSavings.savings >= 0)
    if (verbose) console.log('offers after savings', offers.length)
  }

  if (filterConfig?.makerCollections) {
    offers = filterOffersByCollections(offers, 'maker', filterConfig.makerCollections)
    if (verbose) console.log('offers after getCollections', offers.length)
  }

  if (filterConfig?.takerCollections) {
    offers = filterOffersByCollections(offers, 'taker', filterConfig.takerCollections)
    if (verbose) console.log('offers after giveCollections', offers.length)
  }

  if (filterConfig?.maker && filterConfig.maker.length > 0) {
    offers = offers.filter((offer) => filterConfig.maker?.includes(offer.onChainSwap?.data.maker ?? ''))
    if (verbose) console.log('offers after maker', offers.length)
  }

  if (filterConfig?.taker && filterConfig.taker.length > 0) {
    offers = offers.filter((offer) => filterConfig.taker?.includes(offer.onChainSwap?.data.taker ?? ''))
    if (verbose) console.log('offers after taker', offers.length)
  }

  if (filterConfig?.makerExclude && filterConfig.makerExclude.length > 0) {
    offers = offers.filter((offer) => !filterConfig.makerExclude?.includes(offer.onChainSwap?.data.maker ?? ''))
    if (verbose) console.log('offers after makerExclude', offers.length)
  }

  if (filterConfig?.takerExclude && filterConfig.takerExclude.length > 0) {
    offers = offers.filter((offer) => !filterConfig.takerExclude?.includes(offer.onChainSwap?.data.taker ?? ''))
    if (verbose) console.log('offers after takerExclude', offers.length)
  }

  switch (filterConfig?.type) {
    case 'all':
      break
    case 'onChain':
      offers = offers.filter((offer) => offer.isOnChain)
      if (verbose) console.log('offers after onChain', offers.length)
      break
    case 'offChain':
      offers = offers.filter((offer) => !offer.isOnChain)
      if (verbose) console.log('offers after offChain', offers.length)
      break
  }

  if (filterConfig?.bestFor) {
    offers = filterBestOffers(offers, filterConfig.bestFor === 'maker')
    if (verbose) console.log('offers after best', offers.length)
  }

  if (verbose) console.log('offers final', offers.length)
  return offers
}

function filterBestOffers(
  offers: SwapData[],
  isMakerPerspective: boolean
): SwapData[] {
  let bestOffers: Record<string, { data: SwapData; savings: number }> = {}
  offers.forEach((offer) => {
    let key = `${offer.makerCollection.collectionName}-${offer.takerCollection.collectionName}`
    let savings = isMakerPerspective ? offer.makerSwapSavings.savings : offer.takerSwapSavings.savings

    let newOffer = { data: offer, savings }

    if (!bestOffers[key]) {
      bestOffers[key] = newOffer
    } else if (savings > bestOffers[key].savings) {
      bestOffers[key] = newOffer
    }
  })
  // console.log('bestOffers', bestOffers)

  return Object.values(bestOffers).map((bestOffer) => bestOffer.data)
}
