import React from 'react'
import { Flex, Box, Image, Text, Button } from '@chakra-ui/react'
import { SwapData, TraitCollectionData } from '../../../../types/collectionSwapV2'
import { formatSignificantDigits } from '../../../../utils'
import { Nft } from '../../../../types/nft'

const Arrows = () => {
  return (
    <div>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 16L0 11L5 6L6.4 7.425L3.825 10H11V12H3.825L6.4 14.575L5 16ZM15 10L13.6 8.575L16.175 6H9V4H16.175L13.6 1.425L15 0L20 5L15 10Z"
          fill="#616161"
        />
      </svg>
    </div>
  )
}

interface SwapOfferCardProps {
  offer: SwapData
  giveNfts: Nft[]
  uid: string | undefined
  handleCreateSwapAction: () => void
  selectBidId: (bidId: string | null) => void
}

const SwapOfferCard: React.FC<SwapOfferCardProps> = ({
  offer,
  giveNfts,
  uid,
  handleCreateSwapAction,
  selectBidId,
}) => {
  const isOnChain = offer.isOnChain
  const isMaker = offer.onChainSwap?.data.maker === uid?.replace('solana-', '') || !isOnChain
  console.log("abc", offer.makerNft)
  const giveItem = isMaker && isOnChain ? offer.makerNft! : giveNfts[0]!
  const getItem = isMaker
    ? {
      name: offer.takerCollection.collectionName,
      image: offer.takerCollection.imageUrl,
      alt: 'traitValue' in offer.takerCollection ? offer.takerCollection.traitValue : undefined,
    }
    : {
      name: offer.makerNft!.name,
      image: offer.makerNft!.image,
      alt: offer.makerNft!.name,
    }
  const savings = isMaker ? offer.makerSwapSavings : offer.takerSwapSavings
  console.log(giveItem)
  return (
    <Box borderRadius="lg" p={{ base: 2, md: 3 }}>
      <Flex
        className={`swap-ui-placeholder ${isOnChain ? 'border-dark-potato' : 'border-dark-potato-dashed'}`}
        alignItems="center"
        justifyContent="space-between"
        borderRadius="xl"
        px={{ base: 2, md: 3 }}
        py={{ base: 2, md: 3 }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Flex alignItems="center" flex={1} justifyContent="space-between" width="100%">
          <div className="flex flex-col items-center">
            <div className="whitespace-nowrap text-center" style={{ fontSize: "11px" }}>
              <Text color="gray.600" fontWeight="bold" display="inline">You</Text>
              <Text className="text-nsPink" fontWeight="bold" display="inline"> Give</Text>
            </div>

            <div className="overflow-hidden flex justify-center">
              <Image
                src={giveItem.image}
                alt="Give NFT"
                boxSize={{ base: '40px', md: '50px' }}
                borderRadius="lg"
              />
            </div>
          </div>

          {savings.swapCost > 0 && (
            <>
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight="bold"
                mr={{ base: 2, md: 4 }}
                color="gray.600"
              >
                +
              </Text>
              <Box
                bg="gray.200"
                borderRadius="lg"
                p={{ base: 1, md: 2 }}
                textAlign="center"
                mr={{ base: 0, md: 4 }}
              >
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="bold"
                  color="gray.600"
                  lineHeight="1"
                  mt={1}
                  mr={1}
                  ml={1}
                >
                  {formatSignificantDigits(savings.swapCost, 2)}
                </Text>
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="bold"
                  color="gray.600"
                  mb={1}
                  mr={1}
                  ml={1}
                >
                  SOL
                </Text>
              </Box>
            </>
          )}

          <Arrows />


          <div className="flex flex-col items-center">
            <div className="whitespace-nowrap text-center" style={{ fontSize: "11px" }}>
              <Text color="gray.600" fontWeight="bold" display="inline">You</Text>
              <Text className="text-strongGreen" fontWeight="bold" display="inline"> Get</Text>
            </div>

            <div className="overflow-hidden flex justify-center">
              <Image
                src={getItem.image}
                alt="Give NFT"
                boxSize={{ base: '40px', md: '50px' }}
                borderRadius="lg"
              />
            </div>
          </div>

          {savings.swapCost < 0 && (
            <>
              <Text
                fontSize={{ base: 'lg', md: 'xl' }}
                fontWeight="bold"
                mr={{ base: 2, md: 4 }}
                color="gray.600"
              >
                +
              </Text>
              <Box
                bg="gray.200"
                borderRadius="lg"
                p={{ base: 1, md: 2 }}
                textAlign="center"
                mr={{ base: 0, md: 4 }}
              >
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="bold"
                  color="gray.600"
                  lineHeight="1"
                  mt={1}
                  mr={1}
                  ml={1}
                >
                  {formatSignificantDigits(-savings.swapCost, 2)}
                </Text>
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight="bold"
                  color="gray.600"
                  mb={1}
                  mr={1}
                  ml={1}
                >
                  SOL
                </Text>
              </Box>
            </>
          )}

          <Box
            bg="gray.200"
            borderRadius="lg"
            p={{ base: 1, md: 2 }}
            textAlign="center"
            mr={{ base: 1, md: 4 }}
            display={{ base: 'none', md: 'block' }}
          >
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
              color="gray.600"
              lineHeight="1"
              mt={1}
              mr={1}
              ml={1}
            >
              Savings{' '}
            </Text>
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight="bold"
              color="green.600"
              mb={1}
              mr={1}
              ml={1}
            >
              {' '}
              💰 {formatSignificantDigits(savings.savings, 2)} SOL
            </Text>
          </Box>

          {!offer.isOnChain ? (
            <Button
              className="secondary-potato-btn"
              size={{ base: 'sm', md: 'md' }}
              mr={{ base: 2, md: 4 }}
              onClick={handleCreateSwapAction}
            >
              Create
            </Button>
          ) : isMaker ? (
            <Button
              className="primary-potato-btn"
              size={{ base: 'sm', md: 'md' }}
              mr={{ base: 2, md: 4 }}
              onClick={() => {
                selectBidId(offer.onChainSwap?.bidId || null)
              }}
            >
              View
            </Button>
          ) : (
            <Button
              className="primary-potato-btn"
              size={{ base: 'sm', md: 'md' }}
              mr={{ base: 2, md: 4 }}
              onClick={() => {
                selectBidId(offer.onChainSwap?.bidId || null)
              }}
            >
              Accept
            </Button>
          )}
        </Flex>
        <Box
          p={{ base: 0, md: 2 }}
          textAlign="center"
          mt={{ base: 0, md: 0 }}
          alignSelf="flex-end"
          display={{ base: 'block', md: 'none' }}
        >
          <Text
            fontSize={{ base: 'xs', md: 'sm' }}
            fontWeight="bold"
            color="gray.600"
            display="inline"
          >
            SAVE{' '}
          </Text>
          <Text
            fontSize={{ base: 'xs', md: 'sm' }}
            fontWeight="bold"
            color="green.600"
            display="inline"
          >
            {' '}
            💰 {formatSignificantDigits(savings.savings, 2)} SOL
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default SwapOfferCard
