import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../contexts/appContext'
import { Spinner } from '@chakra-ui/react'
import { useUA } from '../../contexts/userTracking'
import { useMediaQuery } from 'react-responsive'
import { MobileSwapCardWithPotateos } from './MobileSwapCard'
import CollectionSwapCard from './CollectionSwapCard'
import { useCollectionSwaps } from '../../contexts/CollectionSwapsContext'
import { FilterConfig, SortConfig, SwapData } from '../../types/collectionSwapV2'
import ProposeSwapCard from './ProposeSwapCard'
import { swapDataToOverview } from '../../utils/swapDataToOverview'
import useSolana from '../../hooks/useSolana'
import { sort } from '../../utils/sort'

interface SwapTableProps {
  offers: SwapData[]
  showCreateSwap?: boolean
  sortConfig?: SortConfig
  showMobileCard?: boolean
  showPotaeos?: boolean
  mode?: 'picker' | 'explore'
  type?: 'normal' | 'trait'
}

const SwapTable: React.FC<SwapTableProps> = ({
  offers,
  showCreateSwap = true,
  sortConfig,
  showMobileCard,
  mode = 'explore',
  type = 'normal',
}) => {
  const { uid, signedIn } = useAppContext()
  const { signIn } = useSolana()
  const { addGAEvent } = useUA()

  const { isLoading, selectBidId, setIsCreateModalOpen } = useCollectionSwaps()
  const [sortedSwaps, setSortedSwaps] = useState<SwapData[]>([])

  useEffect(() => {
    offers = sort(offers, sortConfig)
    setSortedSwaps(offers)
  }, [offers, sortConfig])

  // const { gaCSSortEvent, gaCSFilterEvent, gaPropose, gaCSUserBalance } = useUA()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const renderItem = (swap: SwapData) => {
    const swapOverview = swapDataToOverview(swap, uid)

    const onSelectSwap = () => {
      addGAEvent(`${mode}_select-swap_click`, { bidId: swap.bidId })
      selectBidId(swap.bidId || null)
    }

    const onCreateSwaps = () => {
      if (!signedIn || !uid) {
        signIn()
      } else {
        addGAEvent(`${mode}_create-swap_click`, { bidId: swap.bidId })
        setIsCreateModalOpen(true)
      }
    }

    if (isMobile || showMobileCard) {
      return (
        <MobileSwapCardWithPotateos
          key={swap.bidId || `${swapOverview.getItem.onChainId}-${swapOverview.giveItem.onChainId}`}
          swapOverview={swapOverview}
          onSelectSwap={onSelectSwap}
          onCreateSwaps={onCreateSwaps}
        />
      )
    } else {
      return (
        <CollectionSwapCard
          key={swap.bidId || `${swapOverview.getItem.onChainId}-${swapOverview.giveItem.onChainId}`}
          {...swapOverview}
          onSelectSwap={onSelectSwap}
          onCreateSwaps={onCreateSwaps}
        />
      )
    }
  }

  const renderItems = () => {
    return sortedSwaps.map((swap) => {
      const userId = uid?.replace('solana-', '')
      const isCurrentUsersSwap = swap.onChainSwap?.data.maker === userId

      if (swap.takerSwapSavings.savings <= 0 && !isCurrentUsersSwap) return null

      return renderItem(swap)
    })
  }

  return (
    <>
      <div className="mb-8 w-full">
        <div
          className={`grid ${isLoading || sortedSwaps.length < 1 || showMobileCard
            ? 'grid-cols-1 pt-4'
            : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'
            } gap-4 md:justify-start`}
        >
          {isLoading ? (
            <div className="w-full flex flex-col items-center justify-center pt-24">
              <Spinner color="#FC822F" size={'xl'} thickness="8px" emptyColor="#666666" />
              <h4 className="pt-2 archivo-black">Loading Swaps...</h4>
            </div>
          ) : (
            [
              showCreateSwap ? (
                <ProposeSwapCard key="propose-swap-card" noSwapsFound={sortedSwaps.length < 1} type={type} />
              ) : null,
              ...renderItems(),
            ]
          )}
        </div>
      </div>
    </>
  )
}

export default SwapTable
