import React, { useState } from 'react';
import { claimStreak } from '../services/user.service';
import { useAppContext } from '../contexts/appContext';
import { PointsStreakValue } from '../types/collectionSwapV2';
import { useNavigate } from 'react-router-dom';
import { useCollectionSwaps } from '../contexts/CollectionSwapsContext';
import useSolana from "../hooks/useSolana";
import { Box, VStack, Text, Button, Spinner, useToast } from '@chakra-ui/react';
import CustomTooltip from '../components/CollectionSwap/CustomTooltip';

export default function Rewards() {
    const { userPoints: points } = useAppContext();
    const { pointsConfiguration } = useCollectionSwaps();
    const navigate = useNavigate();
    const { signIn } = useSolana();

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    const BidsTooltip = () => {
        return (<CustomTooltip
            label={`Earn more potatoes 🥔 with each consecutive bid every 24 hours! Start with 5🥔 on your first bid, 10🥔 for the second, 15🥔 for the third, and so on, up to 60 potatoes on your 12th bid. Maximize your daily total of up to 390🥔 by placing all 12 bids. The more you bid, the more you earn!`}
        >
            <div tabIndex={0} style={{ cursor: 'pointer', display: 'inline-block' }}>
                <svg
                    width="12"
                    height="12"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.6665 12.6666H9.33317V7.66663H7.6665V12.6666ZM8.49984 5.99996C8.73595 5.99996 8.93386 5.9201 9.09359 5.76038C9.25331 5.60065 9.33317 5.40274 9.33317 5.16663C9.33317 4.93051 9.25331 4.7326 9.09359 4.57288C8.93386 4.41315 8.73595 4.33329 8.49984 4.33329C8.26373 4.33329 8.06581 4.41315 7.90609 4.57288C7.74636 4.7326 7.6665 4.93051 7.6665 5.16663C7.6665 5.40274 7.74636 5.60065 7.90609 5.76038C8.06581 5.9201 8.26373 5.99996 8.49984 5.99996ZM8.49984 16.8333C7.34706 16.8333 6.26373 16.6145 5.24984 16.177C4.23595 15.7395 3.354 15.1458 2.604 14.3958C1.854 13.6458 1.26025 12.7638 0.822754 11.75C0.385254 10.7361 0.166504 9.65274 0.166504 8.49996C0.166504 7.34718 0.385254 6.26385 0.822754 5.24996C1.26025 4.23607 1.854 3.35413 2.604 2.60413C3.354 1.85413 4.23595 1.26038 5.24984 0.822876C6.26373 0.385376 7.34706 0.166626 8.49984 0.166626C9.65261 0.166626 10.7359 0.385376 11.7498 0.822876C12.7637 1.26038 13.6457 1.85413 14.3957 2.60413C15.1457 3.35413 15.7394 4.23607 16.1769 5.24996C16.6144 6.26385 16.8332 7.34718 16.8332 8.49996C16.8332 9.65274 16.6144 10.7361 16.1769 11.75C15.7394 12.7638 15.1457 13.6458 14.3957 14.3958C13.6457 15.1458 12.7637 15.7395 11.7498 16.177C10.7359 16.6145 9.65261 16.8333 8.49984 16.8333Z"
                        fill="#828282"
                    />
                </svg>
            </div>
        </CustomTooltip>)
    }



    const ClaimButton = ({ dayKey, pointStreak }: { dayKey: string; pointStreak: PointsStreakValue | undefined }) => {

        const toast = useToast()
        const [isClaimed, setIsClaimed] = useState(pointStreak?.claimed || false);
        const [isLoading, setIsLoading] = useState(false);

        let isDisabled = !pointStreak || !pointStreak.timestamp || isClaimed;

        const handleClick = async () => {
            if (isDisabled || isLoading) return;

            setIsLoading(true);

            try {
                await claimStreak(dayKey);
                setIsClaimed(true);
                toast({
                    title: `Streak claimed! You got ${pointStreak?.value}🥔.`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } catch (error) {
                console.error('Error claiming streak:', error);
                toast({
                    title: `Error claiming streak! Meow! Please try again.`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                })
            } finally {
                setIsLoading(false);
            }
        };

        return (
            <Button
                className={`potato-radius secondary-potato-btn archivo-black ${isDisabled ? "disabled" : ""} `}
                disabled={isDisabled || isLoading}
                onClick={handleClick}
                size="sm"
            >
                {isLoading ? <Spinner size="sm" /> : isClaimed ? "CLAIMED" : "CLAIM"}
            </Button>
        );
    };


    const RewardBidRow = ({ description, action, score, emoji }: { description: string; action: React.ReactNode; score: React.ReactNode; emoji: string }) => {
        const shouldShowSpinner = !score || Number(score) === 0;

        return (
            <div className="flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius new-bg-transparent text-white transition-all duration-200">
                <div className="flex items-center flex-1 min-w-0">
                    <span className="mr-2 text-xl">{emoji}</span>
                    <span className="font-medium truncate">{description}</span>
                </div>
                <div className="flex items-center">
                    {shouldShowSpinner ? (
                        <Spinner size="sm" className="w-20 sm:w-24 text-right whitespace-nowrap mr-4" />
                    ) : (
                        <span className="archivo-black w-20 sm:w-24 text-right whitespace-nowrap mr-4">🥔 {score}</span>
                    )}
                    <BidsTooltip />
                    <span className="w-24 sm:w-32 text-right">{action}</span>
                </div>

            </div>
        );
    };


    const RewardRow = ({ description, action, score, emoji }: { description: string; action: React.ReactNode; score: React.ReactNode; emoji: string }) => {
        const shouldShowSpinner = !score || Number(score) === 0;

        return (
            <div className="flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 text-xs sm:text-sm potato-radius new-bg-transparent text-white transition-all duration-200">
                <div className="flex items-center flex-1 min-w-0">
                    <span className="mr-2 text-xl">{emoji}</span>
                    <span className="font-medium truncate">{description}</span>
                </div>
                <div className="flex items-center">
                    {shouldShowSpinner ? (
                        <Spinner size="sm" className="w-20 sm:w-24 text-right whitespace-nowrap mr-4" />
                    ) : (
                        <span className="archivo-black w-20 sm:w-24 text-right whitespace-nowrap mr-4">🥔 {score}</span>
                    )}
                    <span className="w-24 sm:w-32 text-right">{action}</span>
                </div>
            </div>
        );
    };

    return (
        <Box className="max-w-7xl mx-auto">
            <VStack spacing={6} align="stretch">
                <Box className="potato-radius new-bg-transparent shadow-lg p-6">
                    <Text className="text-2xl archivo-black mb-4">🎁 Rewards</Text>
                    <Text>
                        Earn delicious potatoes 🥔 by interacting with the Potato Cat app! Whether you're a beginner or a pro, you can collect these treats by engaging with our website. Check out the ways to earn potatoes and how many you can get.
                    </Text>
                    <Text mt={4}>
                        Boost your potatoes with multipliers, available through specific actions
                    </Text>
                </Box>

                <div className="w-full potato-radius new-bg-transparent shadow-lg p-2 sm:p-4 mb-10">
                    <div className="flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 archivo-black text-white text-xs sm:text-sm">
                        <div className="flex items-center flex-1">
                            <span className="ml-2 sm:ml-12">Action</span>
                        </div>
                        <div className="flex items-center">
                            <span className="w-20 sm:w-24 text-right mr-4">Reward</span>
                            <span className="w-24 sm:w-32 text-right"> </span>
                        </div>
                    </div>
                    <RewardBidRow
                        description={`Active bids (every 24 hours)`}
                        action={
                            <Button onClick={() => handleNavigate("/create")} size="sm" className="potato-radius secondary-potato-btn archivo-black">
                                Create
                            </Button>
                        }
                        score={pointsConfiguration?.pointsPerActiveBid.length > 0
                            ?
                            `${pointsConfiguration.pointsPerActiveBid[1]}, ${pointsConfiguration.pointsPerActiveBid[2]}, ${pointsConfiguration.pointsPerActiveBid[3]}...` : null}
                        emoji="🎁"
                    />
                    <RewardRow
                        description={`Someone accepts your bid`}
                        action={
                            <Button onClick={() => handleNavigate("/create")} size="sm" className="potato-radius secondary-potato-btn archivo-black">
                                Create
                            </Button>
                        }
                        score={pointsConfiguration.pointsPerTakerAcceptedSwap}
                        emoji="🎁"
                    />
                    <RewardRow
                        description={`You accept a bid`}
                        action={
                            <Button onClick={() => handleNavigate("/profile")} size="sm" className="potato-radius secondary-potato-btn archivo-black">
                                Show bids
                            </Button>
                        }
                        score={pointsConfiguration.pointsPerMakerAcceptedSwap}
                        emoji="🎁"
                    />
                    <RewardRow
                        description={`Connect wallet (first time)`}
                        action={
                            <Button onClick={signIn} size="sm" className="potato-radius secondary-potato-btn archivo-black" isDisabled={points !== null}>
                                {points !== null ? "Connected" : "Connect"}
                            </Button>
                        }
                        score={pointsConfiguration.pointsFirstConnection}
                        emoji="🎁"
                    />
                    <RewardRow
                        description={`Complete profile (name, email & avatar)`}
                        action={
                            <Button onClick={() => handleNavigate("/edit-profile")} size="sm" className="potato-radius secondary-potato-btn archivo-black">
                                Edit profile
                            </Button>
                        }
                        score={pointsConfiguration.pointsProfileCompletion}
                        emoji="🎁"
                    />
                    <RewardRow
                        description={`Complete survey`}
                        action={
                            <Button onClick={() => { window.open("https://tulle.cc/potato-cat-survey", "_blank"); }} size="sm" className="potato-radius secondary-potato-btn archivo-black">
                                Survey
                            </Button>
                        }
                        score={pointsConfiguration.pointsCompletingSurvey}
                        emoji="🎁"
                    />
                    <RewardRow
                        description={`Pet the potato cat (daily limit: 300)`}
                        action={
                            <Button onClick={() => handleNavigate("/")} size="sm" className="potato-radius secondary-potato-btn archivo-black">
                                Pet Cat
                            </Button>
                        }
                        score={pointsConfiguration.pointsPerPetttingCat}
                        emoji="🎁"
                    />
                </div>

                <Box className="potato-radius new-bg-transparent shadow-lg p-6">
                    <Text className="text-xl archivo-black mb-4">📅 Daily Streak</Text>
                    <Text>
                        By joining the app every day you can earn potatoes. The longer the streak, the more potatoes you can earn. We reset your progress after claiming 27 streak or when you miss a day!
                    </Text>
                    {(points && points.accumulatedPoints.streak > 0) && (
                        <Text className='flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 text-xs sm:text-sm font-medium truncate potato-radius new-bg-transparent text-white transition-all duration-200' mt={2}>You have already earned {Math.round(points.accumulatedPoints.streak)}🥔 from streaks.</Text>
                    )}
                    {points && (
                        <Text className='flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 text-xs sm:text-sm font-medium truncate potato-radius new-bg-transparent text-white transition-all duration-200' mt={2}>Currently you have a {points.daily.streakDays} {points.daily.streakDays > 1 ? "days" : "day"} streak.</Text>
                    )}
                </Box>

                <div className="w-full potato-radius new-bg-transparent shadow-lg p-2 sm:p-4 mb-10">
                    <div className="flex justify-between items-center rounded-lg p-2 sm:p-4 mb-2 archivo-black text-white text-xs sm:text-sm">
                        <div className="flex items-center flex-1">
                            <span className="ml-2 sm:ml-12">Streak</span>
                        </div>
                        <div className="flex items-center">
                            <span className="w-20 sm:w-24 text-right mr-4">Reward</span>
                            <span className="w-24 sm:w-32 text-right"> </span>
                        </div>
                    </div>

                    <RewardRow
                        description="3 days streak"
                        action={<ClaimButton pointStreak={points?.streak.day3} dayKey="day3" />}
                        score={pointsConfiguration.points3DaysStreak}
                        emoji="📅"
                    />
                    <RewardRow
                        description="8 days streak"
                        action={<ClaimButton pointStreak={points?.streak.day8} dayKey="day8" />}
                        score={pointsConfiguration.points8DaysStreak}
                        emoji="📅"
                    />
                    <RewardRow
                        description="13 days streak"
                        action={<ClaimButton pointStreak={points?.streak.day13} dayKey="day13" />}
                        score={pointsConfiguration.points13DaysStreak}
                        emoji="📅"
                    />
                    <RewardRow
                        description="20 days streak"
                        action={<ClaimButton pointStreak={points?.streak.day20} dayKey="day20" />}
                        score={pointsConfiguration.points20DaysStreak}
                        emoji="📅"
                    />
                    <RewardRow
                        description="27 days streak"
                        action={<ClaimButton pointStreak={points?.streak.day27} dayKey="day27" />}
                        score={pointsConfiguration.points27DaysStreak}
                        emoji="📅"
                    />
                </div>
            </VStack>
        </Box>
    );
}