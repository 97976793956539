import React from 'react';
import AngryCat from '../../../assets/angrypc.png';
import { Nft } from "../../../types/nft";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import CloseButton from '../Buttons/CloseButton'
import ActionButton from '../Buttons/ActionButton'
import { useAppContext } from '../../../contexts/appContext'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'

interface PickerNftModalProps {
  isOpen: boolean
  onClose: () => void
  onAction: (nfts: Nft[]) => Promise<void>
}

interface NFTCardProps {
  nft: Nft;
  selectedNftId: string | null;
  onSelectNft: (nfts: Nft[]) => void;
}

interface NFTSelectorProps {
  nftsToDisplay: Nft[];
  selectedNftId: string | null;
  onSelectNft: (nfts: Nft[]) => void;
  nfts?: Nft[];
}

interface SelectionModalProps {
  isOpen: boolean
  isDisabled: boolean
  onClose: () => void
  renderNfts: () => JSX.Element
  onAction: () => void
  buttonText?: string
}

const SelectionModal: React.FC<SelectionModalProps> = ({ isOpen, isDisabled, onClose, renderNfts, onAction, buttonText }) => {
  return (<Modal
    isOpen={isOpen}
    onClose={onClose}
    size="2xl"
    closeOnOverlayClick={false}
    trapFocus={false}
  >
    <ModalOverlay />
    <ModalContent className="potato-modal-bg potato-radius" maxW={'400px'}>
      <ModalHeader></ModalHeader>
      <ModalBody p={0} className="text-dark-potato">
        <div className="flex flex-col px-4 justify-center mb-4">
          {renderNfts()}
        </div>
        <div className="w-full flex justify-between  gap-2 px-6 pb-6">
          <CloseButton onClick={onClose} />
          <ActionButton
            onClick={onAction}
            text={buttonText ? buttonText : 'Next'}
            isDisabled={isDisabled}
            tooltipDisabledText="Select NFT"
          />
        </div>
      </ModalBody>
    </ModalContent>
  </Modal>)
}

// Subcomponent for the checkmark SVG
const CheckmarkSVG = () => (
  <svg
    width="42"
    height="28"
    viewBox="0 0 42 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.86261 14.8096L14.3866 25.7697"
      stroke="#008E20"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.1374 2.22998L14.3864 25.77"
      stroke="#008E20"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Subcomponent for individual NFT card
const NFTCard: React.FC<NFTCardProps> = ({ nft, selectedNftId, onSelectNft }) => (
  <div
    key={nft.itemId}
    className={`relative collection-card potato-radius-sm cursor-pointer overflow-hidden ${selectedNftId === nft.itemId ? 'border-2 border-orange-500 bg-orange-500 text-white' : ''
      }`}
  >
    <img
      src={nft.image}
      alt="NFT"
      className="w-full"
      onClick={() => onSelectNft([nft])}
    />
    {selectedNftId === nft.itemId && (
      <div className="absolute top-12 left-10">
        <CheckmarkSVG />
      </div>
    )}
    <h4 className="text-sm p-2 pb-1 font-bold">
      {nft.collection.name && nft.collection.name.length > 12
        ? `${nft.collection.name.slice(0, 9)}...`
        : nft.collection.name}
    </h4>
    <h4 className="text-xs px-2 pb-2">
      {nft.name.length > 25 ? `${nft.name.slice(0, 22)}...` : nft.name}
    </h4>
  </div>
);


const NoNFTsAvailable: React.FC<{ message?: string }> = ({ message }) => (
  <div className="w-full flex flex-col items-center justify-center px-3 py-20 text-center">
    <p className="mb-4">
      {message ? message : "You don't have any supported NFTs available from this collection."}
    </p>
    <img
      src={AngryCat}
      style={{ maxWidth: '100px', width: '100%', height: 'auto' }}
      alt="Angry Potato Cat"
    />
  </div>
);

const NFTSelector: React.FC<NFTSelectorProps> = ({ nftsToDisplay, selectedNftId, onSelectNft }) => {
  return (
    <>
      <h4 className="font-bold pb-2">
        Select NFT to <span className="text-secondary-pinkFloyd">Give</span>
        <p className="font-normal text-sm">Please select the item you would like to give.</p>
      </h4>
      <div>
        {nftsToDisplay.length > 0 ? (
          <div
            className={'grid grid-cols-3 gap-3 max-h-[470px] overflow-y-auto px-2 collections-box pt-3 pb-3 potato-radius'}
          >
            {nftsToDisplay.map((nft) => (
              <NFTCard
                key={nft.itemId}
                nft={nft}
                selectedNftId={selectedNftId}
                onSelectNft={onSelectNft}
              />
            ))}
          </div>
        ) : (
          <NoNFTsAvailable />
        )}
      </div>
    </>
  );
};


const NFTAvatarSelector: React.FC<NFTSelectorProps> = ({ nftsToDisplay, selectedNftId, onSelectNft }) => {
  return (
    <>
      <h4 className="font-bold pb-2">
        Select NFT for your avatar
      </h4>
      <div>
        {nftsToDisplay.length > 0 ? (
          <div
            className={'grid grid-cols-3 gap-3 max-h-[470px] overflow-y-auto px-2 collections-box pt-3 pb-3 potato-radius'}
          >
            {nftsToDisplay.map((nft) => (
              <NFTCard
                key={nft.itemId}
                nft={nft}
                selectedNftId={selectedNftId}
                onSelectNft={onSelectNft}
              />
            ))}
          </div>
        ) : (
          <NoNFTsAvailable message={"Purr-tato! You don't have any NFTs in the wallet!"}
          />
        )}
      </div>
    </>
  );
};



const PickerNftModal: React.FC<PickerNftModalProps> = function ({
  isOpen,
  onClose,
  onAction,
}) {

  const { nfts } = useAppContext()
  const {
    giveNfts,
    giveCollections,
    setGiveNfts,
  } = useCollectionSwaps()


  const giveNftId = useMemo(() => {
    return giveNfts?.[0]?.itemId
  }, [giveNfts])

  const allowedNfts = useMemo(() => {
    if (!nfts) return []

    if (giveCollections.length > 0) {
      return nfts.filter((nft) =>
        giveCollections.some((collection) => collection.onChainId === nft.collection.onChainId)
      )
    } else {
      return []
    }
  }, [giveCollections, nfts])

  const renderNfts = (): JSX.Element => (
    <NFTSelector
      nftsToDisplay={allowedNfts}
      selectedNftId={giveNftId}
      onSelectNft={setGiveNfts}
    />
  );

  return (
    <SelectionModal
      isOpen={isOpen}
      isDisabled={giveNfts.length === 0}
      onClose={onClose}
      renderNfts={renderNfts}
      onAction={() => {
        onAction(giveNfts)
      }} />
  )
}

export const PickerNftUserImageModal: React.FC<PickerNftModalProps> = ({ isOpen, onClose, onAction }) => {
  const { nfts, avatar } = useAppContext()
  const {
    giveNfts,
    setGiveNfts,
  } = useCollectionSwaps()

  const giveNftId = useMemo(() => {
    if (giveNfts.length > 0) return giveNfts[0].itemId

    const avatarNft = nfts?.find((nft) => nft.image === avatar)
    return avatarNft ? avatarNft.itemId : ""
  }, [nfts, giveNfts])


  const legitNfts = useMemo(() => {
    if (!nfts) return []
    return nfts.filter((nft) => !nft.collection.verified.isSpam)
  }, [nfts])

  const renderNfts = (): JSX.Element => (
    <NFTAvatarSelector
      nftsToDisplay={legitNfts}
      selectedNftId={giveNftId}
      onSelectNft={setGiveNfts}
    />
  );


  return (
    <SelectionModal
      isOpen={isOpen}
      isDisabled={giveNfts.length === 0}
      onClose={onClose}
      renderNfts={renderNfts}
      onAction={() => {
        onAction(giveNfts)
        onClose()
      }}
      buttonText={"Set as Avatar"} />
  )
}


export default PickerNftModal;