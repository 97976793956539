import { Alert, AlertDescription, AlertIcon, ModalBody } from '@chakra-ui/react'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import SurplusComponent from '../Surplus'
import SwapDetailsComponent from '../SwapDetails'
import useSolana from '../../../hooks/useSolana'
import ActionButton, { ActionButtonProps } from '../Buttons/ActionButton'
import CloseButton from '../Buttons/CloseButton'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import { swapDataToOverview } from '../../../utils/swapDataToOverview'
import TransactionTable from '../TransactionTable'
import { Points } from '../Points'

interface SignStepProps {
  previousStep: () => void
  nextStep: () => void
}

const SignStep: React.FC<SignStepProps> = function ({ previousStep, nextStep }) {
  const { uid, balance, nfts } = useAppContext()
  const { connected, connect } = useSolana()
  const { selectedBid, solToUsd, transactions, fetchTakeSwapTransactions, transactionsStatus, setShouldRefresh, waveFees } =
    useCollectionSwaps()
  const [actionButtonProps, setActionButtonProps] = useState<ActionButtonProps>({
    onClick: () => { },
    isDisabled: false,
    text: 'Signing...',
  })

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect])

  const swapOverview = useMemo(() => {
    if (!selectedBid) return null
    return swapDataToOverview(selectedBid, uid)
  }, [selectedBid, uid])

  const handleFetchTransactions = useCallback(() => {
    if (uid && selectedBid) {
      fetchTakeSwapTransactions(uid)
    }
  }, [uid, selectedBid, fetchTakeSwapTransactions])

  useEffect(() => {
    if (uid && selectedBid) {
      handleFetchTransactions()
    }
  }, [uid, selectedBid, handleFetchTransactions])

  useEffect(() => {
    if (transactionsStatus === 'success') {
      setShouldRefresh(true)
      nextStep()
    } else if (transactionsStatus === 'error') {
      setActionButtonProps({
        onClick: handleFetchTransactions,
        isDisabled: false,
        text: 'Retry',
      })
    } else if (transactionsStatus === 'loading') {
      setActionButtonProps({
        onClick: () => { },
        isDisabled: true,
        text: 'Signing...',
      })
    }
  }, [transactionsStatus, nextStep, handleFetchTransactions])

  return (
    <ModalBody p={0}>
      <div className="p-6 text-dark-potato">
        <h2 className="pb-4 archivo-black font-2xl">Instant Swap</h2>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          {/* LEFT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            {swapOverview && <SwapDetailsComponent {...swapOverview} past={false} />}
          </div>

          {/* RIGHT OF THE MODAL */}
          <div className="w-full md:w-1/2">
            {/* TAKER */}
            <TransactionTable
              isLoadingTransactions={!transactions.length}
              transactions={transactions}
            />
            <Points mode="accept" />

            {!waveFees && (
              <Alert
                status="info"
                className="info-alert my-4 potato-radius"
                background={
                  'linear-gradient(270deg, rgba(0, 0, 0, 0.30) 0%, rgba(19, 13, 13, 0.15) 100%)'
                }
                borderRadius={'4px'}
                padding={'20px'}
              >
                <AlertIcon color={'#eb792b'} />
                <AlertDescription className="text-xs" lineHeight={'14px'}>
                  {
                    'Potato Cat charges a 5% fee on savings, and royalties are paid on the SOL exchanged.'
                  }
                </AlertDescription>
              </Alert>
            )}

            {swapOverview?.userSavings && (
              <SurplusComponent
                userSavings={swapOverview?.userSavings}
                chainUsdValue={solToUsd}
                calculatedDate={''}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-full justify-between flex gap-2 px-6 pb-6">
        {/* <CloseButton onClick={onClose} /> */}
        <CloseButton onClick={previousStep} text="< Back" />
        <ActionButton {...actionButtonProps} />
      </div>
    </ModalBody>
  )
}

export default SignStep
