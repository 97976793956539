import { Box, Flex, Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { FaBars, FaTimes } from 'react-icons/fa'
import Logo from '../../assets/logo.png'
import { useAppContext } from '../../contexts/appContext'
import SignInButton from '../SignInButton'
import CustomTooltip from '../CollectionSwap/CustomTooltip'
import AnnouncementBar from '../AnnouncementBar';

const routes = [
  { id: 'swap', label: 'Swap', to: '/', isNew: false },
  { id: 'collection-swap', label: 'Explore', to: '/explore', isNew: false },
  { id: 'leaderboard', label: 'Leaderboard', to: '/leaderboard', isDisabled: false },
  { id: 'rewards', label: 'Rewards', to: '/rewards', isNew: false },
  // { id: 'collection', label: 'Collection', to: '/collection', isNew: false },
]

interface NavbarProps {
  top?: string;
}

export default function Navbar({ top }: NavbarProps) {
  const { uid } = useAppContext()
  const { isOpen, onToggle } = useDisclosure()

  return (
    <>
      <nav id="nav-bar" className="main-menu-content" style={top ? { top } : {}}>
        <AnnouncementBar />  {/* Add the AnnouncementBar here */}

        <Flex className="nav-content page-width" align="center" justify="space-between" wrap="nowrap" p={2}>
          <Box as={NavLink} to="https://app.potatocat.ai/" className="logo" flexShrink={0} position="relative">
            <img src={Logo} alt="NeoSwap" style={{ height: '40px' }} />
            <Box
              as="span"
              position="absolute"
              top="-9px"
              right="-30px"
              bg="#444444"
              color="white"
              fontSize="9px"
              px={1}
              borderRadius="sm"
              className='archivo-black'
            >
              Alpha
            </Box>
          </Box>

          <Flex display={{ base: 'none', md: 'flex' }} align="center">
            {routes.map(({ id, label, to, isDisabled, isNew }) => (
              isDisabled ? (
                <CustomTooltip key={id} label="Coming soon! Meow!">
                  <Button as="span" variant="ghost" color="white" mx={2}>
                    {label}
                  </Button>
                </CustomTooltip>
              ) : (
                <Button key={id} as={NavLink} to={to} variant="ghost" color="white" mx={2}>
                  {label}
                  {isNew && (
                    <Box as="span" w="6px" h="6px" bg="#FCD34D" borderRadius="50%" position="absolute" top={0} right={0} transform="translate(50%, -50%)" />
                  )}
                </Button>
              )
            ))}
          </Flex>

          <Flex align="center">
            <Box display={{ base: 'none', md: 'block' }}>
              <SignInButton />
            </Box>

            {/* <Menu isOpen={isOpen} placement="bottom-end">
              <MenuButton
                as={Button}
                className='potato-radius-sm new-bg-transparent'
                onClick={onToggle}
                display={{ base: 'flex', md: 'none' }}
                ml={2}
              >
                {isOpen ? <FaTimes /> : <FaBars />}
              </MenuButton>
              <MenuList bg="#444444">
                {routes.map(({ id, label, to, isDisabled }) => (
                  <MenuItem key={id} as={NavLink} to={to} isDisabled={isDisabled}>
                    {label}
                  </MenuItem>
                ))}
                <MenuItem>
                  <SignInButton />
                </MenuItem>
              </MenuList>
            </Menu> */}
          </Flex>
        </Flex>
      </nav>
    </>
  )
}