import {
  Box,
  Text,
  Link,
  Flex,
  Image,
  Tabs,
  TabPanels,
  TabPanel,
  TabList,
  Tab,
} from '@chakra-ui/react'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import useSolana from '../../../hooks/useSolana'
import { useAppContext } from '../../../contexts/appContext'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { GiveCollectionModal, GetCollectionModal } from '../PickerModal/CollectionModal'
import { ChevronDownIcon } from '@chakra-ui/icons'
import SwapTable from '../SwapTable'
import SwapModal from '../SwapModal'
import { CollectionData, FilterConfig, SortConfig } from '../../../types/collectionSwapV2'
import CustomTooltip from '../CustomTooltip'
import { useUA } from '../../../contexts/userTracking'
import { getPotateosForBids } from '../../../utils'
import { useNavigate } from 'react-router-dom'

const SHOW_POTATEOS = true

const DEFAULT_GIVE_COLLECTIONS = ['Mad Lads']
const DEFAULT_GET_COLLECTIONS = ['Mad Lads', 'DeGods', 'Claynosaurz', 'SMB Gen2']

const Picker: React.FC = function ({ }) {
  const navigate = useNavigate()
  const {
    giveCollections,
    getCollections,
    calculatedPointsConfiguration,
    setGetCollections,
    setGiveCollections,
    setGiveNfts,
    getOffers,
    setIsCreateModalOpen,
    collectionsData,
  } = useCollectionSwaps()
  const { signIn, connected, connect } = useSolana()
  const { uid, signedIn } = useAppContext()
  const { addGAEvent } = useUA()

  const [giveModalOpen, setGiveModalOpen] = useState(false)
  const [getModalOpen, setGetModalOpen] = useState(false)

  const createLink = useMemo(() => {
    const makeSlugs = (collections: CollectionData[]) =>
      collections.map((col) => col.collectionName.toLowerCase().replace(' ', '_')).join(',')

    const giveParam = makeSlugs(giveCollections)
    const getParam = makeSlugs(getCollections)

    const params = new URLSearchParams()
    if (giveParam) params.append('give', giveParam)
    if (getParam) params.append('get', getParam)

    return `/explore${params.toString() ? `?${params.toString()}` : ''}`
  }, [giveCollections, getCollections])

  const [filterConfig, setFilterConfig] = useState({
    type: 'all',
    getCollections: [],
    giveCollections: [],
  } as FilterConfig)

  const handleFindSwaps = () => {
    const newFilterConfig = {
      ...filterConfig,
      getCollections: getCollections.map((collection) => collection.collectionName),
      giveCollections: giveCollections.map((collection) => collection.collectionName),
    }
    setFilterConfig(newFilterConfig)
  }

  useEffect(() => {
    handleFindSwaps()
  }, [giveCollections, getCollections])

  const offers = useMemo(() => {
    let onChainFilterConfig = {
      type: 'onChain',
      makerCollections: getCollections.map((collection) => collection.collectionName),
      takerCollections: giveCollections.map((collection) => collection.collectionName),
      bestFor: 'taker',
      makerExclude: [(uid || '').replace('solana-', '')],
    } as FilterConfig

    let onChainOffers = getOffers(onChainFilterConfig)

    let offChainFilterConfig = {
      type: 'offChain',
      takerCollections: getCollections.map((collection) => collection.collectionName),
      makerCollections: giveCollections.map((collection) => collection.collectionName),
      bestFor: 'maker',
    } as FilterConfig

    let offChainOffers = getOffers(offChainFilterConfig)

    return [...onChainOffers, ...offChainOffers]
  }, [filterConfig, getOffers])

  const handleCreateSwaps = () => {
    addGAEvent('picker_create-swaps_click')
    setIsCreateModalOpen(true)
  }

  useEffect(() => {
    if (!connected && uid) {
      connect()
    }
  }, [connected, uid, connect])

  const canCreateSwap = useMemo(() => {
    return connected && uid && giveCollections.length > 0 && getCollections.length > 0
  }, [connected, uid, giveCollections, getCollections])

  useEffect(() => {
    setGetCollections(DEFAULT_GET_COLLECTIONS)
    setGiveCollections(DEFAULT_GIVE_COLLECTIONS)

    return () => {
      setGetCollections([])
      setGiveCollections([])
      setGiveNfts([])
    }
  }, [])

  const handleGiveCollectionClick = () => {
    addGAEvent('picker_give-collection_click')
    setGiveModalOpen(true)
  }

  const handleGetCollectionClick = () => {
    if (giveCollections.length === 0) return
    addGAEvent('picker_get-collection_click')
    setGetModalOpen(true)
  }

  const renderCollectionContent = () => (
    <>
      <Flex w="100%" alignItems="center" className="swap-ui-placeholder potato-radius px-2 py-8">
        {giveCollections.length ? (
          <Image
            key={'giveCollection'}
            src={giveCollections[0]?.imageUrl}
            alt={giveCollections[0]?.collectionName}
            className="potato-radius-sm new-bg-transparent"
            boxSize="40px"
            mr={2}
            onClick={handleGiveCollectionClick}
          />
        ) : (
          <Box
            className="potato-radius-sm new-bg-transparent"
            boxSize="40px"
            onClick={handleGiveCollectionClick}
          />
        )}
        <button
          className="px-4 py-1 potato-btn-radius primary-potato-btn text-white inter-bold swap-btn"
          onClick={handleGiveCollectionClick}
        >
          Give
          <ChevronDownIcon />
        </button>
      </Flex>

      <Flex
        mt={2}
        w="100%"
        alignItems="center"
        className="swap-ui-placeholder potato-radius px-2 py-8"
      >
        {getCollections.length ? (
          <Flex flexWrap="wrap" alignItems="center">
            {getCollections.map((collection, index) => (
              <Fragment key={index}>
                {index > 0 && (
                  <Text fontSize="sm" mr="1">
                    {' '}
                    OR{' '}
                  </Text>
                )}
                {index > 0 && index % 4 === 0 && <Flex w="100%" h="4" />}
                <Image
                  src={collection.imageUrl}
                  alt={collection.collectionName}
                  boxSize="40px"
                  borderRadius="md"
                  mr={2}
                  onClick={handleGetCollectionClick}
                />
              </Fragment>
            ))}
          </Flex>
        ) : (
          <Box
            className="potato-radius-sm new-bg-transparent"
            boxSize="40px"
            onClick={handleGetCollectionClick}
          />
        )}
        <CustomTooltip
          label="Please select a collection to give first"
          isDisabled={giveCollections.length > 0}
        >
          <button
            className="px-4 py-1 potato-btn-radius primary-potato-btn text-white inter-bold swap-btn"
            onClick={handleGetCollectionClick}
            disabled={giveCollections.length === 0}
          >
            Get
            <ChevronDownIcon />
          </button>
        </CustomTooltip>
      </Flex>
    </>
  )

  const [isLoading, setIsLoading] = useState(true)

  const filteredCollections = useMemo(() => {
    const filtered = collectionsData
      .filter((collection) => collection.enabledTraitBids)
      .sort((a, b) => b.collectionName.localeCompare(a.collectionName));

    setIsLoading(filtered.length === 0);
    return filtered;
  }, [collectionsData]);

  const renderCollectionTabs = () => (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      overflowX="auto"
      py={2}
      borderTopRadius="xl"
      mb="-2px"  // Overlap with the main container
    >
      <Box
        as="button"
        px={4}
        py={2}
        borderRadius="full"
        className='swap-ui'
        mr={2}
        fontSize="sm"
        fontWeight="bold"
      >
        Swap
      </Box>
      {isLoading ? (
        // Skeleton loader for up to 5 circles
        <>
          {[...Array(5)].map((_, index) => (
            <div key={index} className="w-9 h-9 rounded-full bg-gray-200 animate-pulse mr-2"></div>
          ))}
        </>
      ) : (
        filteredCollections.map((collection) => (
          <Box
            key={collection.slug}
            as="button"
            onClick={() => navigate(`/collection/${collection.slug}`)}
            position="relative"
            mr={2}
          >
            <Image
              src={collection.imageUrl}
              alt={collection.collectionName}
              boxSize="36px"
              borderRadius="full"
              border="2px solid transparent"
              _hover={{ border: "2px solid orange" }}
            />
          </Box>
        ))
      )}
    </Flex>
  )

  return (
    <Box maxW="sm" mx="auto">
      {renderCollectionTabs()}
      <Box
        className="swap-ui"
        bg="white"
        borderRadius="xl"
        p={4}
        boxShadow="md"
      >
        {renderCollectionContent()}

        <Box textAlign="center" mt={2}>
          {!connected || !uid || !signedIn ? (
            <button
              className="w-full px-8 py-2 potato-btn-radius primary-potato-btn text-center archivo-black text-white"
              onClick={signIn}
            >
              Connect Wallet
            </button>
          ) : (
            <button
              className="w-full px-8 py-2 potato-btn-radius primary-potato-btn text-center archivo-black text-white"
              onClick={() => handleCreateSwaps()}
              disabled={!canCreateSwap}
            >
              Create Swaps{' '}
              {SHOW_POTATEOS && getCollections.length
                ? `(+ ${calculatedPointsConfiguration.pointsPerMakerAcceptedSwap +
                getPotateosForBids(
                  getCollections.length,
                  calculatedPointsConfiguration.pointsPerActiveBid
                )
                } 🥔)`
                : ''}
            </button>
          )}

          {getCollections.length > 0 && (
            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-full">
                <Text fontSize="sm" className="archivo-black text-left" mt={5}>
                  Best Swaps🔝
                </Text>
              </div>
              <SwapTable
                offers={offers}
                showCreateSwap={false}
                showMobileCard={true}
                showPotaeos={true}
                mode={'picker'}
              />
              <SwapModal />
            </div>
          )}

          {getCollections.length > 0 && (
            <Text fontSize="sm" mt={2} color="#FC822F" textDecoration="underline">
              <Link href={createLink} target='_blank' onClick={() => addGAEvent('picker_view-more_click')}>
                + View more
              </Link>
            </Text>
          )}

          <Text fontSize="sm" mt={2}>
            Earn 🥔 Potatoes!
          </Text>
        </Box>

        <GiveCollectionModal
          isOpen={giveModalOpen}
          onClose={() => setGiveModalOpen(false)}
        />
        <GetCollectionModal
          isOpen={getModalOpen}
          onClose={() => setGetModalOpen(false)}
        />
      </Box>
    </Box>
  )
}

export default Picker