import { useCallback, useEffect, useMemo, useState } from 'react'
import { CollectionData, TraitCollectionData, TraitData } from '../types/collectionSwapV2'
import { streamNftCollectionsV2, streamNftCollectionsV2Traits } from '../services/nfts.service'
import { Nft } from '../types/nft'

export interface UseCollectionsType {
  collectionsData: CollectionData[]

  giveCollections: CollectionData[]
  setGiveCollections: (collections: CollectionData[] | string[]) => void
  getCollections: CollectionData[]
  setGetCollections: (collections: CollectionData[] | string[]) => void

  traitCollectionSlug: string | null
  setTraitCollectionSlug: (slug: string | null) => void
  traitCollection: CollectionData | null
  traitCollectionsData: TraitCollectionData[]

  getTraitCollections: TraitCollectionData[]
  setGetTraitCollections: (collections: TraitCollectionData[] | string[]) => void

  findTraitCollectionWBuySellForNfts: (nfts: Nft[]) => (CollectionData | null)[]
  getTraitCollectionIds: string[]
  setGetTraitCollectionIds: React.Dispatch<React.SetStateAction<string[]>>
}

export default function useCollections(): UseCollectionsType {
  const [collectionsData, setCollectionsData] = useState<CollectionData[]>([])

  const [traitCollectionSlug, setTraitCollectionSlug] = useState<string | null>("")
  const [traitData, setTraitData] = useState<TraitData[]>([])
  const [giveCollectionNames, setGiveCollectionNames] = useState<string[]>([])
  const [getCollectionNames, setGetCollectionNames] = useState<string[]>([])

  const [getTraitCollectionIds, setGetTraitCollectionIds] = useState<string[]>([])

  useEffect(() => {
    const unsubscribe = streamNftCollectionsV2(setCollectionsData)

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => {
      unsubscribe()
    }
  }, [])

  const traitCollection = useMemo(() => {
    if (!traitCollectionSlug || !collectionsData) return null

    let collection = collectionsData.find(
      (col) => col.slug.toLowerCase() === traitCollectionSlug.toLowerCase()
    )

    if (!collection || !collection?.enabledTraitBids) return null

    return collection
  }, [traitCollectionSlug, collectionsData])

  useEffect(() => {
    if (!traitCollection) return
    const unsubscribe = streamNftCollectionsV2Traits(
      `solana-${traitCollection.onChainId}`,
      setTraitData
    )

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => {
      unsubscribe()
    }
  }, [traitCollection])

  const traitCollectionsData: TraitCollectionData[] = useMemo(() => {
    if (!traitData || !traitCollection) return []

    return traitData
      .map((trait) => ({
        ...traitCollection,
        ...trait,
        imageUrl: trait.image,
        buyPrice: Math.max(trait.stats.floorPrice, traitCollection.buyPrice),
        sellPrice: Math.max(trait.stats.highestBid, traitCollection.sellPrice),
      }))
      .filter((trait) => trait.stats.count > 0)
      .filter((trait) => trait.stats.count < trait.totalNfts)
  }, [traitData, traitCollection])

  const giveCollections = useMemo(() => {
    return giveCollectionNames
      .map((name) => collectionsData.find((col) => col.collectionName === name))
      .filter(Boolean) as CollectionData[] // Filter out any undefined values if not all names are found in collectionsData
  }, [collectionsData, giveCollectionNames])

  const getCollections = useMemo(() => {
    return (getCollectionNames
      .map((name) => collectionsData.find((col) => col.collectionName === name))
      .filter(Boolean) as CollectionData[])
  }, [collectionsData, getCollectionNames])

  const setGiveCollections = (collections: CollectionData[] | TraitCollectionData[] | string[]) => {
    if (collections.length === 0) {
      setGiveCollectionNames([])
    } else if (typeof collections[0] === 'string') {
      setGiveCollectionNames(collections as string[])
    } else {
      setGiveCollectionNames((collections as CollectionData[]).map((col) => col.collectionName))
    }
  }

  const setGetCollections = (collections: CollectionData[] | TraitCollectionData[] | string[]) => {
    if (collections.length === 0) {
      setGetCollectionNames([])
    } else if (typeof collections[0] === 'string') {
      setGetCollectionNames(collections as string[])
    } else {
      setGetCollectionNames((collections as CollectionData[]).map((col) => col.collectionName))
    }
  }
  const setGetTraitCollections = (collections: TraitCollectionData[] | string[]) => {
    if (collections.length === 0) {
      setGetTraitCollectionIds([])
    } else if (typeof collections[0] === 'string') {
      setGetTraitCollectionIds(collections as string[])
    } else {
      setGetTraitCollectionIds((collections as TraitCollectionData[]).map((col) => col.traitId))
    }
  }

  const getTraitCollections = useMemo(() => {
    return getTraitCollectionIds
      .map((id) => traitCollectionsData.find((col) => col.traitId === id))
      .filter(Boolean) as TraitCollectionData[] // Filter out any undefined values if not all ids are found in traitCollectionsData
  }, [traitCollectionsData, getTraitCollectionIds])

  const findTraitCollectionWBuySellForNfts = useCallback((nfts: Nft[]) => {
    if (!traitCollection) return []

    return nfts.map((nft) => {
      if (nft.collection.onChainId !== traitCollection?.onChainId) {
        return null
      }
      let traitCollectionForNft = JSON.parse(JSON.stringify(traitCollection))
      nft.attributes.map((attribute) => {
        let traitId = `${attribute.trait_type}_${attribute.value}`.toLowerCase()
        let tmp = traitCollectionsData.find((trait) => trait.traitId.toLowerCase() === traitId)
        if (tmp?.buyPrice && tmp.buyPrice > traitCollectionForNft.buyPrice) {
          traitCollectionForNft.buyPrice = tmp.buyPrice
        }
        if (tmp?.sellPrice && tmp.sellPrice > traitCollectionForNft.sellPrice) {
          traitCollectionForNft.sellPrice = tmp.sellPrice
        }
      })
      return traitCollectionForNft
    })
  }, [traitCollectionsData, traitCollection])

  return {
    collectionsData,
    giveCollections,
    setGiveCollections,
    getCollections,
    setGetCollections,
    traitCollectionSlug,
    setTraitCollectionSlug,
    traitCollection,
    traitCollectionsData,
    getTraitCollections,
    setGetTraitCollections,
    findTraitCollectionWBuySellForNfts,
    getTraitCollectionIds,
    setGetTraitCollectionIds,
  }
}
