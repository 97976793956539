import React, { useMemo } from 'react'
import { useCollectionSwaps } from '../../../../contexts/CollectionSwapsContext'
import { Flex, Box, Image, Text } from '@chakra-ui/react'
import { useAppContext } from '../../../../contexts/appContext'
import SwapOfferCard from './SwapOfferCard'

const BestSwapsTable: React.FC = () => {
    const { uid } = useAppContext()
    const {
        giveNfts,
        setCreateModalStep,
        setIsCreateModalOpen,
        setModalMode,
        selectBidId,
        getTraitOnChainOffers,
        getTraitCalculatedOffers,
        getTraitCollections,
    } = useCollectionSwaps()

    const filteredOffers = useMemo(() => {
        const onChainOffers = getTraitOnChainOffers()
        const calculatedOffers = getTraitCalculatedOffers()

        const offers = getTraitCollections.map((traitCollection) => {
            let id = traitCollection.traitId.toLowerCase()
            let calculatedOffer = calculatedOffers.find((offer) => {
                if ('traitId' in offer.takerCollection) {
                    return offer.takerCollection.traitId.toLowerCase() === id
                }
                return false
            })
            let offers = onChainOffers
                .filter((offer) => {
                    if (offer.makerNft) {
                        const makerNftTraitIds = offer.makerNft.attributes.map((attribute) =>
                            (attribute.trait_type + '_' + attribute.value).toLowerCase()
                        )
                        if (getTraitCollections.length === 0) return true
                        return makerNftTraitIds.includes(id)
                    }
                    return false
                })
                .filter((offer) => offer.onChainSwap?.data.maker !== uid?.replace('solana-', ''))
                .sort((a, b) => a.takerSwapSavings.swapCost - b.takerSwapSavings.swapCost)
                .slice(0, 1)

            if (calculatedOffer) {
                offers.push(calculatedOffer)
            }
            return {
                trait: traitCollection,
                offers,
            }
        })

        return offers
    }, [getTraitOnChainOffers, getTraitCalculatedOffers, getTraitCollections])

    const handleCreateSwapAction = () => {
        setCreateModalStep(0)
        setIsCreateModalOpen(true)
        setModalMode("trait")
    }

    return (
        <>
            <Flex justifyContent="center" width={{ base: '100%', md: '60%' }}>
                <Box width="100%" textAlign="left">
                    <Text fontSize="xx-large" fontWeight="bold" color="white" mt={4} mb={4}>
                        Best Swaps
                    </Text>

                    {filteredOffers.map(({ trait, offers }) => {
                        return (
                            <Box key={trait.traitId} mb={{ base: 2, md: 4 }}>
                                <Flex
                                    alignItems="center"
                                    className='new-bg-transparent'
                                    // border="2px solid #a1a1a1"
                                    borderRadius="full"
                                    p={{ base: 1, md: 2 }}
                                    mb={{ base: 1, md: 2 }}
                                // bg="gray.70"
                                >
                                    <Image
                                        src={trait.image}
                                        alt={trait.traitValue}
                                        boxSize={{ base: '30px', md: '45px' }}
                                        borderRadius="full"
                                        mr={{ base: 1, md: 2 }}
                                        border="1px solid #a1a1a1"
                                    />
                                    <Text fontWeight="bold" color="white" fontSize={{ base: 'md', md: 'large' }}>
                                        {trait.traitKey}: {trait.traitValue}
                                    </Text>
                                </Flex>
                                {giveNfts.length > 0 && offers.map((offer) => (
                                    <SwapOfferCard
                                        key={offer.bidId || offer.traitId}
                                        offer={offer}
                                        giveNfts={giveNfts}
                                        uid={uid}
                                        handleCreateSwapAction={handleCreateSwapAction}
                                        selectBidId={selectBidId}
                                    />
                                ))}
                                {giveNfts.length === 0 && (
                                    <Text color="gray.500" fontSize="sm" mt={2}>
                                        No NFTs available for swap.
                                    </Text>
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Flex>
        </>
    )
}

export default BestSwapsTable
