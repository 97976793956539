import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useAppContext } from '../../../contexts/appContext'
import useSolana from '../../../hooks/useSolana'
import { useCollectionSwaps } from '../../../contexts/CollectionSwapsContext'
import NextStep from './NextStep'
import SignStep from './SignStep'
import DetailsStep from './DetailsStep'
import PickerNftModal from '../PickerModal/NftModal'
import { Nft } from '../../../types/nft'

interface CollectionSwapCreateModalProps {
  isOpen: boolean
  onClose: () => void
}

const CollectionSwapCreatetModal: React.FC<CollectionSwapCreateModalProps> = function ({
  isOpen,
  onClose,
}) {
  const [hasStartedSigning, setHasStartedSigning] = useState<boolean>(false)

  const { connected, connect } = useSolana()
  const { setGiveNfts, setCreateModalStep, createModalStep } = useCollectionSwaps()

  const { uid } = useAppContext()

  useEffect(() => {
    if (uid && !connected) {
      connect()
    }
  }, [uid, connect])

  const handleCloseAndReset = () => {
    setCreateModalStep(-1)
    setHasStartedSigning(false)
    onClose()
  }

  const handlePickNfts = async (nfts: Nft[]) => {
    setGiveNfts(nfts)
    setCreateModalStep(0)
  }

  const renderStep = () => {
    switch (createModalStep) {
      case 0:
        return (
          <SignStep
            previousStep={() => {
              setCreateModalStep(-1)
            }}
            nextStep={() => {
              setCreateModalStep(1)
            }}
            onClose={handleCloseAndReset}
            hasStartedSigning={hasStartedSigning}
            setHasStartedSigning={setHasStartedSigning}
          />
        )
      case 1:
        return (
          <DetailsStep
            previousStep={() => {
              setCreateModalStep(0)
            }}
          />
        )
      default:
        return (
          <NextStep
            onClose={handleCloseAndReset}
            nextStep={() => {
              return
            }}
          />
        )
    }
  }

  if (createModalStep === -1) {
    return <PickerNftModal isOpen={isOpen} onClose={handleCloseAndReset} onAction={handlePickNfts} />
  } else {
    return (
      <Modal
        isOpen={isOpen}
        onClose={handleCloseAndReset}
        size="4xl"
        closeOnOverlayClick={false}
        trapFocus={false}
      >
        <ModalOverlay />
        <ModalContent className="potato-modal-bg potato-radius text-dark-potato">
          <ModalCloseButton />
          {renderStep()}
        </ModalContent>
      </Modal>
    )
  }
}

export default CollectionSwapCreatetModal
