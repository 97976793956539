import React, { useEffect, useMemo, useState } from 'react'
import {
  useSearchParams, useNavigate
} from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useUA } from '../contexts/userTracking'

import { useCollectionSwaps } from '../contexts/CollectionSwapsContext'
import SwapTable from '../components/CollectionSwap/SwapTable'
import ProposeSwapStickyButton from '../components/CollectionSwap/ProposeSwapStickyButton'
import SwapModal from '../components/CollectionSwap/SwapModal'
import { SortConfig, FilterConfig } from '../types/collectionSwapV2'

import { Filter, Sort, FilteredCollections } from '../components/CollectionSwap/FilterAndSort'
import { useAppContext } from '../contexts/appContext'

const CollectionSwap: React.FC = () => {

  const [scroll, setScroll] = useState(false)

  const { selectBidId, getOffers, getTraitOnChainOffers } = useCollectionSwaps()

  const { uid } = useAppContext()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50)
    })
  }, [])

  const { addGAEvent } = useUA()

  const [searchParams] = useSearchParams()

  const parseCollections = (param: string | null): string[] | undefined => {
    if (!param) return undefined;
    return param.split(',').map(col => col.replace('_', ' '));
  };

  const [giveGetFilterConfig, setGiveGetFilterConfig] = useState<{ getCollections?: string[], giveCollections?: string[] }>({})
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    sortBy: 'time',
    sortDirection: 'desc',
  })



  useEffect(() => {
    setSortConfig({
      ...sortConfig,
      uid: uid
    })
  }, [uid]);


  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (params.toString() === '') {
      // Reset filter settings when there are no query parameters
      setGiveGetFilterConfig({})
    } else {
      // Update filter settings based on URL parameters
      setGiveGetFilterConfig({
        getCollections: parseCollections(params.get('get')),
        giveCollections: parseCollections(params.get('give')),
      });
    }
  }, [searchParams]);

  const offers = useMemo(() => {
    let otherOffers = getOffers({
      type: 'onChain',
      makerCollections: giveGetFilterConfig.getCollections,
      takerCollections: giveGetFilterConfig.giveCollections,
      makerExclude: [(uid || '').replace("solana-", "")],
    })
    let userOffers = getOffers({
      type: 'onChain',
      makerCollections: giveGetFilterConfig.giveCollections,
      takerCollections: giveGetFilterConfig.getCollections,
      maker: [(uid || '').replace("solana-", "")],
    })
    return [...otherOffers, ...userOffers]
  }, [giveGetFilterConfig, getOffers])

  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (giveGetFilterConfig.getCollections && giveGetFilterConfig.getCollections.length > 0) {
      params.set('get', giveGetFilterConfig.getCollections.map(col => col.replace(' ', '_')).join(','));
    } else {
      params.delete('get');
    }

    if (giveGetFilterConfig.giveCollections && giveGetFilterConfig.giveCollections.length > 0) {
      params.set('give', giveGetFilterConfig.giveCollections.map(col => col.replace(' ', '_')).join(','));
    } else {
      params.delete('give');
    }

    navigate(`?${params.toString()}`, { replace: true });
  }, [giveGetFilterConfig]);

  const handleFilterSettingsChange = (newGiveGetFilterConfig: typeof giveGetFilterConfig) => {
    setGiveGetFilterConfig(newGiveGetFilterConfig);
  };

  useEffect(() => {
    addGAEvent('explore_filter_change', { filterConfig: giveGetFilterConfig })
  }, [giveGetFilterConfig.getCollections, giveGetFilterConfig.giveCollections])

  useEffect(() => {
    addGAEvent('explore_sort_change', { sortConfig: sortConfig })
  }, [sortConfig])

  const bidIdFromURL = searchParams.get('bidId')

  useEffect(() => {
    if (bidIdFromURL) {
      addGAEvent('explore_url_select-bid', { bidIdFromURL })
      selectBidId(bidIdFromURL)
    }
  }, [bidIdFromURL])

  return (
    <>
      <Helmet>
        <title>Potato Cat - Explore</title>
      </Helmet>
      <div className="max-w-7xl mx-auto pb-6">
        <div className="text-center mb-8">
          <h1 className="text-white text-center text-6xl archivo-black value-prop">
            Next-Gen <br></br>
            NFT Swap
          </h1>
          <p className="text-center text-white text-xl font-regular leading-loose value-prop-sub">
            Get NFTs you want using NFTs you own
          </p>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-between gap-4 px-4 md:px-6 pb-6">
          <div className="w-full md:w-auto">
            <Filter setFilterConfig={handleFilterSettingsChange} filterConfig={giveGetFilterConfig} />
          </div>
          <div className="w-full md:w-auto">
            <Sort setSortConfig={setSortConfig} sortConfig={sortConfig} />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-between gap-4 px-4 md">
          <FilteredCollections setFilterConfig={setGiveGetFilterConfig} filterConfig={giveGetFilterConfig} />
        </div>
        <SwapTable
          offers={offers}
          sortConfig={sortConfig}
        />

        <SwapModal />
      </div>

      <ProposeSwapStickyButton />
    </>
  )
}

export default CollectionSwap
