import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import {
  SwapData as OnChainSwapData,
  UTILS,
  BundleTransaction,
  CREATE_INSTRUCTIONS as colIx,
  TraitBid,
  TakeSArg,
  EnvOpts,
} from '@neoswap/solana-collection-swap'

import { getNftDetails } from '../services/nfts.service'
import {
  SwapData,
  SortConfig,
  FilterConfig,
  OnChainSwap,
  CollectionData,
  OnChainSwapType,
} from '../types/collectionSwapV2'
import { calculateOffers, calculateOnChainOffer } from '../utils/collectionSwapCalculator'
import { Nft } from '../types/nft'
import { filterCollections, filterOffers } from '../utils/filter'
import { useSearchParams } from 'react-router-dom'
import useSolana from '../hooks/useSolana'
import { useToast } from '@chakra-ui/react'
import { useAppContext } from './appContext'
import { useUA } from './userTracking'
import useCollections, { UseCollectionsType } from '../hooks/useCollections'
import {
  BidTraits,
  getBidsTraits,
  createTraitBid,
  TraitFilter,
  getProof,
} from '../services/traitbids'
import { arraysEqual } from '../utils'

const REFRESH_RATE_INTERVAL = 300000
const CHECK_TRANSACTION_INTERVAL = 5000

let PRIORITIZATION_FEE: number | undefined = undefined
PRIORITIZATION_FEE = 1e6

declare global {
  interface BigInt {
    toJSON(): Number
  }
}

BigInt.prototype.toJSON = function () {
  return Number(this)
}

let envOpts = {
  prioritizationFee: PRIORITIZATION_FEE,
  clusterOrUrl: process.env.REACT_APP_SOLANA_NETWORK!,
  // programId: '2vumtPDSVo3UKqYYxMVbDaQz1K4foQf6A31KiUaii1M7',
  // programId:''
  // idl?: Idl | true,
} as EnvOpts

type TransactionsStatus = 'loading' | 'signing' | 'checking' | 'success' | 'error'

type Points = {
  pointsPerActiveBid: number[]
  pointsPerTakerAcceptedSwap: number
  pointsPerMakerAcceptedSwap: number
  pointsProfileCompletion: number
  pointsFirstConnection: number
  pointsBackpackWallet: number
  pointsPerPetttingCat: number
  pointsCompletingSurvey: number
  pointsChatbotSurvey: number
  pointsTwitter: number
  points3DaysStreak: number
  points8DaysStreak: number
  points13DaysStreak: number
  points20DaysStreak: number
  points27DaysStreak: number
}
// Define the context shape
interface CollectionSwapsContextType extends UseCollectionsType {
  // onChainSwapsData: { sda: string; data: OnChainSwapData }[]
  // loadOnChainSwaps: () => void

  // onChainOffers: SwapData[]

  isLoading: boolean

  getOffers: (filterConfig?: FilterConfig) => SwapData[]
  getTraitOnChainOffers: () => SwapData[]
  getTraitCalculatedOffers: () => SwapData[]
  onChainOffersWithTraits: SwapData[]

  selectBidId: (bidId: string | null) => void
  selectedBidId: string | null

  setSelectedBid: (bid: SwapData | undefined) => void
  selectedBid: SwapData | undefined

  // calculatedOffers: SwapData[]

  solToUsd: number | undefined

  waveFees: boolean

  transactions: BundleTransaction[]
  transactionsStatus: TransactionsStatus | undefined
  fetchTakeSwapTransactions: (uid: string) => Promise<void>
  fetchMakeSwapTransactions: (uid: string) => Promise<void>
  fetchTraitMakeSwapTransactions: (uid: string) => Promise<void>
  fetchCancelSwapTransactions: (uid: string) => Promise<void>
  fetchCancelSingleBidTransactions: (uid: string) => Promise<void>

  isCreateModalOpen: boolean
  setIsCreateModalOpen: (isOpen: boolean) => void

  createModalStep: number
  setCreateModalStep: (step: number) => void

  modalMode: 'collection' | 'trait'
  setModalMode: (mode: 'collection' | 'trait') => void

  traitPickerMode: 'TraitPicker' | 'BestSwaps'
  setTraitPickerMode: (mode: 'TraitPicker' | 'BestSwaps') => void

  onModalClose: () => void

  giveNfts: Nft[]
  setGiveNfts: (nfts: Nft[]) => void

  shouldRefresh: boolean
  setShouldRefresh: (shouldRefresh: boolean) => void
  pointsConfiguration: Points
  calculatedPointsConfiguration: Points

  setActiveTab: (tab: 'swap' | 'explore') => void;
  activeTab: 'swap' | 'explore';

  getTraitCollectionIds: string[]
  setGetTraitCollectionIds: React.Dispatch<React.SetStateAction<string[]>>
}

// Create the context
const CollectionSwapsContext = createContext<CollectionSwapsContextType | undefined>(undefined)

// Create a provider component
export const CollectionSwapsProvider = ({ children }: { children: ReactNode }) => {
  let network = process.env.REACT_APP_SOLANA_NETWORK!

  const { checkTransactions, signTransactions } = useSolana()
  const {
    uid,
    getUserNfts,
    getTokenBalance,
    getRewards,
    pointsMultipliers,
    rewards,
    rewardsWBonus,
  } = useAppContext()
  const toast = useToast()

  // const [calculatedOffers, setCalculatedOffers] = useState<SwapData[]>([])

  const [onChainSwapsData, setOnChainSwapsData] = useState<
    { sda: string; data: OnChainSwapData }[]
  >([])
  const [isOnChainSwapsLoading, setIsOnChainSwapsLoading] = useState(true)
  const [isNftDetailsLoading, setIsNftDetailsLoading] = useState(true)

  // const isLoading = isOnChainSwapsLoading || isNftDetailsLoading || !calculatedOffers.length
  const isLoading = isOnChainSwapsLoading || isNftDetailsLoading

  const [nftDetails, setNftDetails] = useState<Nft[]>([])
  const [bidsTraits, setBidsTraits] = useState<BidTraits[]>([])

  const [selectedBidId, setSelectedBidId] = useState<string | null>(null)
  const [selectedBid, setSelectedBid] = useState<SwapData | undefined>(undefined)

  const [solToUsd, setSolToUsd] = useState<number | undefined>(undefined)

  const [transactions, setTransactions] = useState<BundleTransaction[]>([])
  const [transactionsStatus, setTransactionsStatus] = useState<TransactionsStatus | undefined>(
    undefined
  )
  const [giveNfts, setGiveNfts] = useState<Nft[]>([])

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [createModalStep, setCreateModalStep] = useState(-1)
  const [modalMode, setModalMode] = useState<'collection' | 'trait'>('collection')

  const [traitPickerMode, setTraitPickerMode] = useState<'TraitPicker' | 'BestSwaps'>('TraitPicker')

  const [activeTab, setActiveTab] = useState<'swap' | 'explore'>('swap');

  const [searchParams, setSearchParams] = useSearchParams()

  const [shouldRefresh, setShouldRefresh] = useState(false)
  const { addGAEvent } = useUA()

  const {
    collectionsData,

    giveCollections,
    getCollections,

    setGiveCollections,
    setGetCollections,

    traitCollectionSlug,
    setTraitCollectionSlug,
    traitCollectionsData,
    traitCollection,

    getTraitCollections,
    setGetTraitCollections,
    findTraitCollectionWBuySellForNfts,
    getTraitCollectionIds,
    setGetTraitCollectionIds,
  } = useCollections()

  useEffect(() => {
    getRewards()
  }, [uid])

  const waveFees = useMemo(() => {
    return pointsMultipliers && pointsMultipliers.length > 0 ? true : false
  }, [pointsMultipliers])

  const pointsConfiguration = useMemo(() => {
    return {
      pointsPerActiveBid: rewards ? rewards?.nBidsPoints.map((x) => Math.round(x)) : [],
      pointsPerTakerAcceptedSwap: rewards ? Math.round(rewards.makeSwapPoints) : 0,
      pointsPerMakerAcceptedSwap: rewards ? Math.round(rewards.takeSwapPoints) : 0,
      pointsProfileCompletion: rewards ? Math.round(rewards.profileCompletePoints) : 0,
      pointsFirstConnection: rewards ? Math.round(rewards.firstTimeConnectPoints) : 0,
      pointsBackpackWallet: rewards ? Math.round(rewards.backpackConnectPoints) : 0,
      pointsPerPetttingCat: rewards ? Math.round(rewards.petCatPoints) : 0,
      pointsCompletingSurvey: rewards ? Math.round(rewards.typeformSurveyPoints) : 0,
      pointsChatbotSurvey: rewards ? Math.round(rewards.chatbotSurveyPoints) : 0,
      pointsTwitter: 0,
      points3DaysStreak: rewards ? Math.round(rewards.streakPoints.day3) : 0,
      points8DaysStreak: rewards ? Math.round(rewards.streakPoints.day8) : 0,
      points13DaysStreak: rewards ? Math.round(rewards.streakPoints.day13) : 0,
      points20DaysStreak: rewards ? Math.round(rewards.streakPoints.day20) : 0,
      points27DaysStreak: rewards ? Math.round(rewards.streakPoints.day27) : 0,
    }
  }, [rewards])

  const calculatedPointsConfiguration = useMemo(() => {
    return {
      pointsPerActiveBid: rewardsWBonus ? rewardsWBonus?.nBidsPoints.map((x) => Math.round(x)) : [],
      pointsPerTakerAcceptedSwap: rewardsWBonus ? Math.round(rewardsWBonus.makeSwapPoints) : 0,
      pointsPerMakerAcceptedSwap: rewardsWBonus ? Math.round(rewardsWBonus.takeSwapPoints) : 0,
      pointsProfileCompletion: rewardsWBonus ? Math.round(rewardsWBonus.profileCompletePoints) : 0,
      pointsFirstConnection: rewardsWBonus ? Math.round(rewardsWBonus.firstTimeConnectPoints) : 0,
      pointsBackpackWallet: rewardsWBonus ? Math.round(rewardsWBonus.backpackConnectPoints) : 0,
      pointsPerPetttingCat: rewardsWBonus ? Math.round(rewardsWBonus.petCatPoints) : 0,
      pointsCompletingSurvey: rewardsWBonus ? Math.round(rewardsWBonus.typeformSurveyPoints) : 0,
      pointsChatbotSurvey: rewardsWBonus ? Math.round(rewardsWBonus.chatbotSurveyPoints) : 0,
      pointsTwitter: 0,
      points3DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day3) : 0,
      points8DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day8) : 0,
      points13DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day13) : 0,
      points20DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day20) : 0,
      points27DaysStreak: rewardsWBonus ? Math.round(rewardsWBonus.streakPoints.day27) : 0,
    }
  }, [rewardsWBonus])

  useEffect(() => {
    const fetchSolToUsd = async () => {
      try {
        const url = 'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd'

        const response = await fetch(url)
        const data = await response.json()

        setSolToUsd(data.solana.usd)
      } catch (error) {
        console.log('error', error)
      }
    }

    // refresh every 5 minutes
    const interval = setInterval(() => {
      fetchSolToUsd()
    }, REFRESH_RATE_INTERVAL)

    fetchSolToUsd()

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])

  // useEffect(() => {
  //   const calculatedOffersTmp = calculateOffers({
  //     collections: collectionsData,
  //     waveMakerFees: waveFees,
  //   })

  //   setCalculatedOffers(calculatedOffersTmp)
  // }, [collectionsData, waveFees])

  const loadOnChainSwaps = async (): Promise<void> => {
    setIsOnChainSwapsLoading(true)
    const sdas = await UTILS.getOpenSda({ clusterOrUrl: network, ...envOpts })

    setOnChainSwapsData(sdas)
    setIsOnChainSwapsLoading(false)
  }

  useEffect(() => {
    loadOnChainSwaps()
  }, [])

  const nftsMints = useMemo(() => {
    return [
      ...onChainSwapsData.map((sda) => sda.data.nftMintMaker),
      ...onChainSwapsData.map((sda) => sda.data.nftMintTaker),
    ]
      .filter((nftMint) => nftMint)
      .filter((nftMint, index, self) => self.indexOf(nftMint) === index)
  }, [onChainSwapsData])

  const traitBidAccounts = useMemo(() => {
    return onChainSwapsData
      .filter((swap) => swap.data.swapType === OnChainSwapType.traits)
      .map((swap) => swap.data.bids.map((bid) => bid.collection))
      .flat()
  }, [onChainSwapsData])

  useEffect(() => {
    const updateBidsTraits = async (traitBidAccounts: string[]) => {
      if (traitBidAccounts.length === 0) return
      let bidsRoots = await Promise.all(
        traitBidAccounts.map(async (traitBidAccount) => {
          try {
            let data = await UTILS.getBidAccountData({ bidAccount: traitBidAccount, ...envOpts })
            return { roots: data.roots, bidAccount: traitBidAccount }
          } catch (error) {
            console.log('error', error)
            return undefined
          }
        })
      ).then((bidsRoots) =>
        bidsRoots.filter(
          (bidRoots): bidRoots is { roots: string[]; bidAccount: string } => bidRoots !== undefined
        )
      )

      const traits = await getBidsTraits(bidsRoots)

      let bidsTraits: BidTraits[] = []
      bidsRoots.map((bidRoots) => {
        let tmp = traits.find((bidTrait) => arraysEqual(bidTrait.roots, bidRoots.roots, true))
        if (tmp) {
          bidsTraits.push({ ...tmp, bidAccount: bidRoots.bidAccount })
        }
      })
      setBidsTraits(bidsTraits)
    }
    updateBidsTraits(traitBidAccounts)
  }, [traitBidAccounts])

  useEffect(() => {
    setIsNftDetailsLoading(true)
    const nftIds = nftsMints.map((nftMint) => `solana-${nftMint}`)
    getNftDetails(nftIds).then((data) => {
      setNftDetails(data.data)
      setIsNftDetailsLoading(false)
    })
  }, [nftsMints])

  const onChainOffers = useMemo(() => {
    if (!collectionsData || collectionsData.length === 0) return []
    if (!nftDetails || nftDetails.length === 0) return []

    let offers = onChainSwapsData
      .filter((swap) => swap.data.swapType == OnChainSwapType.native)
      .map((onChainSwap) => {
        const makerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintMaker)
        const takerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintTaker)
        const makerCollection = collectionsData.find(
          (collection) => collection.onChainId === makerNft?.collection.onChainId
        )

        return onChainSwap.data.bids.map((bid) => {
          const takerCollection = collectionsData.find(
            (collection) => collection.onChainId === bid.collection
          )
          if (!makerCollection || !takerCollection) return undefined

          return calculateOnChainOffer({
            onChainSwap: onChainSwap,
            makerCollection: makerCollection,
            takerCollection: takerCollection,
            makerNft: makerNft,
            takerNft: takerNft,
            bid: bid,
            waveMakerFees: waveFees,
            waveTakerFees: waveFees,
          })
        })
      })
      .flat()
      .filter((offer): offer is SwapData => offer !== undefined)

    return offers
  }, [onChainSwapsData, nftDetails, collectionsData, waveFees])

  const onChainOffersWithTraits = useMemo(() => {
    if (!bidsTraits || bidsTraits.length === 0) {
      console.log('bidsTraits is empty')
      return []
    }
    if (!nftDetails || nftDetails.length === 0) {
      console.log('nftDetails is empty')
      return []
    }
    if (!traitCollection || !traitCollectionsData || traitCollectionsData.length === 0) {
      console.log('traitCollection is empty')
      return []
    }

    let offers = onChainSwapsData
      .filter((swap) => swap.data.swapType == OnChainSwapType.traits)
      .map((onChainSwap) => {
        const makerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintMaker)
        const takerNft = nftDetails.find((nft) => nft.address === onChainSwap.data.nftMintTaker)
        const makerCollection = collectionsData.find(
          (collection) => collection.collectionName === makerNft?.collection.name
        )

        if (!makerCollection || makerCollection.collectionId !== traitCollection.collectionId)
          return undefined

        return onChainSwap.data.bids.map((bid) => {
          const bidTraits = bidsTraits.find((bidTrait) => bidTrait.bidAccount === bid.collection)
          if (!bidTraits) return undefined
          if (
            bidTraits.collectionId.replace('solana-', '') !==
            traitCollection.collectionId.replace('solana-', '')
          )
            return undefined
          const bidTrait = bidTraits.traits[0]
          const traitId = `${bidTrait.trait}_${bidTrait.values[0]}`.toLowerCase()

          const takerCollection = traitCollectionsData.find(
            (collection) => collection.traitId.toLowerCase() === traitId
          )
          if (!makerCollection || !takerCollection) return undefined

          return calculateOnChainOffer({
            onChainSwap: onChainSwap,
            makerCollection: makerCollection,
            takerCollection: takerCollection,
            makerNft: makerNft,
            takerNft: takerNft,
            bid: bid,
            waveMakerFees: waveFees,
            waveTakerFees: waveFees,
            roots: bidTraits.roots,
          })
        })
      })
      .flat()
      .filter((offer): offer is SwapData => offer !== undefined)
    return offers
  }, [onChainSwapsData, nftDetails, traitCollection, traitCollectionsData, waveFees, bidsTraits])

  const getOffers = useCallback(
    (filterConfig?: FilterConfig): SwapData[] => {
      let offers: SwapData[] = []

      if (filterConfig?.type === 'onChain' || filterConfig?.type === 'all') {
        offers = [...offers, ...onChainOffers]
      }

      if (filterConfig?.type === 'offChain' || filterConfig?.type === 'all') {
        let calculatedOffers = calculateOffers({
          giveCollections: filterCollections(collectionsData, filterConfig?.makerCollections),
          getCollections: filterCollections(collectionsData, filterConfig?.takerCollections),
          waveMakerFees: waveFees,
        })
        offers = [...offers, ...calculatedOffers]
      }

      offers = filterOffers(offers, filterConfig)

      return offers
    },
    [onChainOffers, collectionsData, waveFees]
  )

  const traitCollectionWBuySellForNfts = useMemo(() => {
    return findTraitCollectionWBuySellForNfts(giveNfts).filter(
      (collection): collection is CollectionData => collection !== null
    )
  }, [giveNfts, findTraitCollectionWBuySellForNfts])

  const getTraitCalculatedOffers = useCallback((): SwapData[] => {
    if (!traitCollection) return []
    let calculatedOffers = calculateOffers({
      giveCollections: traitCollectionWBuySellForNfts,
      getCollections: getTraitCollections,
      waveMakerFees: waveFees,
    })
    return calculatedOffers
  }, [getTraitCollections, traitCollection, waveFees, traitCollectionWBuySellForNfts])

  const getTraitOnChainOffers = useCallback(() => {
    const attributes = giveNfts[0]?.attributes || []
    const giveNftTraitIds = attributes.map((attribute) =>
      (attribute.trait_type + '_' + attribute.value).toLowerCase()
    )

    const onChainOffers = onChainOffersWithTraits
      .filter((offer) => {
        if ('traitId' in offer.takerCollection) {
          const takerCollectionTraitId = offer.takerCollection.traitId.toLowerCase()
          // console.log('takerCollectionTraitId', takerCollectionTraitId)
          if (giveNftTraitIds.length === 0) return true
          return giveNftTraitIds.includes(takerCollectionTraitId)
        }
        return false
      })
      .filter((offer) => {
        if (offer.makerNft) {
          const makerNftTraitIds = offer.makerNft.attributes.map((attribute) =>
            (attribute.trait_type + '_' + attribute.value).toLowerCase()
          )
          if (getTraitCollections.length === 0) return true
          return getTraitCollections.some((collection) =>
            makerNftTraitIds.includes(collection.traitId.toLowerCase())
          )
        }
        return false
      })

    return onChainOffers
  }, [giveNfts, getTraitCollections, onChainOffersWithTraits])
  // const getOffers = useCallback(
  //   (filterConfig?: FilterConfig, sortConfig?: SortConfig): SwapData[] => {
  //     let offers = [...onChainOffers, ...calculatedOffers]

  //     offers = filter(offers, filterConfig)
  //     offers = sort(offers, sortConfig)

  //     return offers
  //   },
  //   [onChainOffers]
  // )

  const selectBidId = (bidId: string | null) => {
    if (bidId) {
      setSearchParams({ bidId: bidId })
    } else {
      setSearchParams({})
    }
    setSelectedBidId(bidId)
  }

  useEffect(() => {
    if (!selectedBidId) {
      return
    }

    let newSelectedBid = onChainOffers.find((offer) => offer.bidId === selectedBidId)

    if (!newSelectedBid) {
      newSelectedBid = onChainOffersWithTraits.find((offer) => offer.bidId === selectedBidId)
    }

    if (newSelectedBid) {
      setSelectedBid(newSelectedBid)
    } else {
      console.warn(
        `Selected bid with ID ${selectedBidId} not found in onChainOffers or onChainOffersWithTraits`
      )
    }
  }, [selectedBidId, onChainOffers, onChainOffersWithTraits])

  const isSwapTraitBid = useMemo(() => {
    return selectedBid && 'traitId' in selectedBid.takerCollection
  }, [selectedBid])

  const fetchTakeSwapTransactions = useCallback(
    async (uid: string) => {
      if (!selectedBid || !selectedBid.onChainSwap) return
      if (!selectedBid.takerNft?.address) return
      if (!selectedBid.onChainSwap.bid) return
      addGAEvent('any_take-swap_fetch-txs', { bidId: selectedBid.bidId })

      setTransactionsStatus('loading')
      let randomNumber = Math.ceil(Math.random() * 100)
      if (randomNumber === 42) randomNumber = 43
      if (waveFees) randomNumber = 42

      let args: TakeSArg = {
        swapDataAccount: selectedBid.onChainSwap?.sda,
        taker: uid.replace('solana-', ''),
        nftMintTaker: selectedBid.takerNft?.address,
        bid: selectedBid.onChainSwap.bid,
        n: randomNumber,
      }
      if (isSwapTraitBid) {
        const proofResponse = await getProof({
          mint: selectedBid.takerNft?.address,
          roots: selectedBid.roots || [],
        })
        if (!proofResponse || !proofResponse.success) throw new Error('No proof response found')
        args.traitProofs = proofResponse.data.proof
        args.traitIndex = proofResponse.data.index
      }

      console.log('args', args)
      let transactions: BundleTransaction[] = []
      try {
        transactions = await colIx.createTakeAndCloseSwapInstructions({ ...args, ...envOpts })
      } catch (error: any) {
        setTransactionsStatus('error')
        addGAEvent('any_take-swap_fetch-txs-error', { bidId: selectedBid.bidId })
        if (error.message) {
          console.error('error', error.message)
          const errorMessage = error?.message?.message?.message
          if (
            typeof errorMessage === 'string' &&
            errorMessage.includes('Account does not exist or has no data')
          ) {
            toast({
              title: 'Error',
              description: 'Ooops! Swap has been taken :(',
              status: 'error',
              duration: 9000,
              isClosable: true,
            })
          }
        }
        return
      }

      setTransactions(transactions)
      setTransactionsStatus('signing')
      addGAEvent('any_take-swap_fetch-txs-success', { bidId: selectedBid.bidId })
    },
    [selectedBid, waveFees]
  )

  const fetchTraitMakeSwapTransactions = useCallback(
    async (uid: string) => {
      if (giveNfts.length !== 1 || getTraitCollections.length < 1) {
        console.log('Not enough giveNfts for makeSwap', giveNfts)
        return
      }

      const giveNft = giveNfts[0]

      addGAEvent('any_make-swap_fetch-txs', { nft: giveNft.address })

      setTransactionsStatus('loading')
      const offers = getTraitCalculatedOffers()

      const makerBids = await Promise.all(
        offers.map(async (offer) => {
          const getCollection = offer.takerCollection
          if (!('traitId' in getCollection)) {
            throw new Error('No traitId found')
          }
          const traitFilters: TraitFilter[] = [
            {
              trait: getCollection.traitKey,
              values: [getCollection.traitValue],
            },
          ]

          console.log('traitFilters', traitFilters)

          const traitBidResponse = await createTraitBid({
            collectionId: getCollection.onChainId,
            traits: traitFilters,
          })

          if (!traitBidResponse || !traitBidResponse.success)
            throw new Error('No trait bid response found')

          console.log('traitBidResponse', traitBidResponse)

          const selectedPairCalculation = offer

          if (!selectedPairCalculation) throw new Error('No selected pair calculation found')
          const makeSwapDetails = selectedPairCalculation.makerSwapOffer
          const takeSwapDetails = selectedPairCalculation.takerSwapOffer
          const collectionMint = getCollection.onChainId
          const amount = -Math.trunc(makeSwapDetails.baseCost)
          const makerNeoswapFee = Math.trunc(makeSwapDetails.fee)
          const makerRoyalties = Math.trunc(makeSwapDetails.royalties)
          const takerNeoswapFee = Math.trunc(takeSwapDetails.fee)
          const takerRoyalties = Math.trunc(takeSwapDetails.royalties)

          console.log('makerTraitBids', {
            collection: collectionMint,
            amount,
            makerNeoswapFee,
            takerNeoswapFee,
            takerRoyalties,
            makerRoyalties,
          })

          return {
            collection: collectionMint,
            amount,
            makerNeoswapFee,
            takerNeoswapFee,
            takerRoyalties,
            makerRoyalties,
            proofs: traitBidResponse.data.roots,
          } as TraitBid
        })
      )

      let args = {
        maker: uid.replace('solana-', ''),
        nftMintMaker: giveNfts[0].address,
        paymentMint: 'So11111111111111111111111111111111111111112',
        traitBids: makerBids,
        endDate: Math.floor(Date.now() / 1000) + 86400 * 5,
      }

      console.log('args', args)

      try {
        const inTransactions = (
          await colIx.createMakeTraitSwapInstructions({ ...args, ...envOpts })
        ).bTxs

        setTransactions(inTransactions)
      } catch (error: any) {
        setTransactionsStatus('error')
        addGAEvent('any_make-swap_fetch-txs-error', { nft: giveNfts[0].address })
        console.error('error', error)

        return
      }

      setTransactionsStatus('signing')
      addGAEvent('any_make-swap_fetch-txs-success', { nft: giveNfts[0].address })
    },
    [giveNfts, getTraitCollections, traitCollection, getTraitCalculatedOffers]
  )

  const fetchMakeSwapTransactions = useCallback(
    async (uid: string) => {
      if (giveNfts.length !== 1 || getCollections.length < 1) {
        if (giveNfts.length > 1) console.log('Too many giveNfts for makeSwap', giveNfts)
        if (giveNfts.length < 1) console.log('Not enough giveNfts for makeSwap', giveNfts)
        if (getCollections.length < 1)
          console.log('Not enough getCollections for makeSwap', getCollections)
        return
      }

      const giveNft = giveNfts[0]

      addGAEvent('any_make-swap_fetch-txs', { nft: giveNft.address })

      setTransactionsStatus('loading')
      const makerBids = getCollections.map((getCollection) => {
        const getCollectionName = getCollection.collectionName
        const selectedPairCalculation = getOffers({
          makerCollections: [giveNft.collection.name],
          takerCollections: [getCollectionName],
          type: 'offChain',
        })[0]

        if (!selectedPairCalculation) throw new Error('No selected pair calculation found')
        const makeSwapDetails = selectedPairCalculation.makerSwapOffer
        const takeSwapDetails = selectedPairCalculation.takerSwapOffer
        const collectionMint = getCollection.onChainId
        const amount = -Math.trunc(makeSwapDetails.baseCost)
        const makerNeoswapFee = Math.trunc(makeSwapDetails.fee)
        const makerRoyalties = Math.trunc(makeSwapDetails.royalties)
        const takerNeoswapFee = Math.trunc(takeSwapDetails.fee)
        const takerRoyalties = Math.trunc(takeSwapDetails.royalties)
        console.log('makerBids', {
          collection: collectionMint,
          amount,
          makerNeoswapFee,
          takerNeoswapFee,
          takerRoyalties,
          makerRoyalties,
        })

        return {
          collection: collectionMint,
          amount,
          makerNeoswapFee,
          takerNeoswapFee,
          takerRoyalties,
          makerRoyalties,
        }
      })
      let args = {
        maker: uid.replace('solana-', ''),
        nftMintMaker: giveNfts[0].address,
        paymentMint: 'So11111111111111111111111111111111111111112',
        bids: makerBids,
        endDate: Math.floor(Date.now() / 1000) + 86400 * 5,
      }
      console.log('args', args)

      try {
        const inTransactions = (await colIx.createMakeSwapInstructions({ ...args, ...envOpts }))
          .bTxs

        setTransactions(inTransactions)
      } catch (error: any) {
        setTransactionsStatus('error')
        addGAEvent('any_make-swap_fetch-txs-error', { nft: giveNfts[0].address })
        console.error('error', error)

        return
      }

      setTransactionsStatus('signing')
      addGAEvent('any_make-swap_fetch-txs-success', { nft: giveNfts[0].address })
    },
    [giveNfts, getCollections, giveCollections]
  )

  const fetchCancelSwapTransactions = useCallback(
    async (uid: string) => {
      if (!selectedBid || !selectedBid.onChainSwap) return

      addGAEvent('any_cancel-swap_fetch-txs', { sda: selectedBid.onChainSwap.sda })

      setTransactionsStatus('loading')
      const args = {
        swapDataAccount: selectedBid.onChainSwap.sda,
        signer: uid.replace('solana-', ''),
      }
      console.log('args', args)
      const transactions = await colIx.createCancelSwapInstructions({ ...args, ...envOpts })

      setTransactions([transactions])
      setTransactionsStatus('signing')
      addGAEvent('any_cancel-swap_fetch-txs-success', { sda: selectedBid.onChainSwap.sda })
    },
    [selectedBid]
  )

  const fetchCancelSingleBidTransactions = useCallback(
    async (uid: string) => {
      if (!selectedBid || !selectedBid.onChainSwap || !selectedBid.onChainSwap.bid) return

      addGAEvent('any_cancel-single-bid_fetch-txs', { bidId: selectedBid.bidId })
      setTransactionsStatus('loading')
      const args = {
        rmBids: [selectedBid.onChainSwap.bid],
        swapDataAccount: selectedBid.onChainSwap.sda,
        maker: uid.replace('solana-', ''),
      }
      const transactions = await colIx.createRmBidBt({ ...args, ...envOpts })

      setTransactions([transactions])
      setTransactionsStatus('signing')
      addGAEvent('any_cancel-single-bid_fetch-txs-success', { bidId: selectedBid.bidId })
    },
    [selectedBid]
  )

  const txDescriptionToGA = (tx: BundleTransaction) => {
    // makeSwap: string;
    // takeSwap: string;
    // payRoyalties: string;
    // payMakerRoyalties: string;
    // payTakerRoyalties: string;
    // claimSwap: string;
    // cancelSwap: string;
    // addBid: string;
    // rmBid: string;
    // setTime: string;
    // close: string;
    const DESC = UTILS.DESC
    let action = Object.keys(DESC).find((key) => DESC[key as keyof typeof DESC] === tx.description)

    if (!action) return tx.description

    switch (action) {
      case 'makeSwap':
        return 'make-swap'
      case 'takeSwap':
        return 'take-swap'
      case 'payRoyalties':
        return 'pay-royalties'
      case 'payMakerRoyalties':
        return 'pay-royalties'
      case 'payTakerRoyalties':
        return 'pay-royalties'
      case 'claimSwap':
        return 'claim-swap'
      case 'cancelSwap':
        return 'cancel-swap'
      case 'addBid':
        return 'add-bid'
      case 'rmBid':
        return 'cancel-single-bid'
      case 'setTime':
        return 'update-end-time'
      case 'close':
        return 'close-swap-account'
    }
  }

  const addGAEventForTransactions = (
    transactions: BundleTransaction[],
    type: 'sign' | 'confirm',
    outcome: 'success' | 'error'
  ) => {
    for (let i = 0; i < transactions.length; i++) {
      const tx = transactions[i]
      const txDescription = txDescriptionToGA(tx)
      addGAEvent(`any_${txDescription}_${type}-tx-${outcome}`, { hash: tx.hash })
    }
  }

  const handleSignTransactions = async () => {
    try {
      let txIdxs = transactions.map((tx, i) => i)

      let minPriority = transactions.reduce((acc, tx) => {
        if (tx.priority === undefined || tx.status !== 'pending') return acc
        return Math.min(acc, tx.priority)
      }, Number.MAX_SAFE_INTEGER)

      txIdxs = txIdxs.filter(
        (i) => transactions[i].priority === minPriority && transactions[i].status === 'pending'
      )

      if (!txIdxs.length) return

      let transactionsTmp = await signTransactions(
        transactions.filter((tx, i) => txIdxs.includes(i))
      )
      addGAEventForTransactions(transactionsTmp, 'sign', 'success')

      transactionsTmp = transactionsTmp.concat(transactions.filter((tx, i) => !txIdxs.includes(i)))

      setTransactions([...transactionsTmp])
    } catch (error: any) {
      addGAEventForTransactions(transactions, 'sign', 'error')
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      setTransactionsStatus('error')
    }
  }

  const addGAEventForTxStatusChange = (
    newTxs: BundleTransaction[],
    oldTxs: BundleTransaction[]
  ) => {
    let txUpdates = newTxs.filter((tx) => {
      let txOld = oldTxs.find((txOld) => txOld.hash === tx.hash)
      if (!txOld) return false

      return txOld.status !== tx.status
    })

    let txUpdatesSuccess = txUpdates.filter((tx) => tx.status === 'success')
    let txUpdatesError = txUpdates.filter((tx) => tx.status === 'failed' || tx.status === 'Timeout')

    addGAEventForTransactions(txUpdatesSuccess, 'confirm', 'success')
    addGAEventForTransactions(txUpdatesError, 'confirm', 'error')
  }

  const handleCheckTransactions = async () => {
    if (!transactions.length) return
    let transactionsTmp = await checkTransactions(transactions)
    addGAEventForTxStatusChange(transactionsTmp, transactions)
    setTransactions([...transactionsTmp])
  }

  useEffect(() => {
    console.log('transactions', transactions)
    // 'loading' | 'signing' | 'checking' | 'success' | 'error'
    let noTxs = transactions.length === 0
    let allSuccess = transactions.every((tx) => tx.status === 'success')
    let someFailed = transactions.some((tx) => tx.status === 'failed' || tx.status === 'Timeout')
    let someChecking = transactions.some((tx) => tx.status === 'broadcast')
    let someSigning = transactions.some((tx) => tx.status === 'pending')

    if (noTxs && transactionsStatus !== 'loading') return setTransactionsStatus(undefined)
    if (noTxs && transactionsStatus === 'loading') return

    if (allSuccess) return setTransactionsStatus('success')
    if (someChecking) return setTransactionsStatus('checking')
    if (someFailed) return setTransactionsStatus('error')
    if (someSigning) return setTransactionsStatus('signing')
  }, [transactions])

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (transactionsStatus === 'signing') handleSignTransactions()
    if (transactionsStatus === 'checking') {
      handleCheckTransactions()
      interval = setInterval(() => {
        handleCheckTransactions()
      }, CHECK_TRANSACTION_INTERVAL)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [transactionsStatus])

  useEffect(() => {
    if (giveNfts.length == 0 && getTraitCollections.length == 0) {
      setTraitPickerMode('TraitPicker')
    }
  }, [giveNfts, getTraitCollections])

  const onModalClose = () => {
    setModalMode('collection')
    setSelectedBidId(null)
    setSelectedBid(undefined)
    // setTraitPickerMode('TraitPicker')
    // setGiveNfts([])
    setTransactions([])
    setIsCreateModalOpen(false)
    setSearchParams({})
    if (shouldRefresh) {
      setShouldRefresh(false)
      loadOnChainSwaps()
      if (uid) {
        getTokenBalance(uid)
        getUserNfts(uid)
      }
    }
  }

  return (
    <CollectionSwapsContext.Provider
      value={{
        // onChainSwapsData,
        isLoading,
        // loadOnChainSwaps,
        collectionsData,
        traitCollectionSlug,
        setTraitCollectionSlug,
        traitCollectionsData,
        traitCollection,
        // onChainOffers,
        getOffers,
        onChainOffersWithTraits,
        // calculatedOffers,
        getTraitOnChainOffers,
        getTraitCalculatedOffers,

        selectBidId,
        selectedBidId,

        setSelectedBid,
        selectedBid,

        solToUsd,

        waveFees,

        transactions,
        transactionsStatus,
        fetchTakeSwapTransactions,
        fetchMakeSwapTransactions,
        fetchTraitMakeSwapTransactions,
        fetchCancelSwapTransactions,
        fetchCancelSingleBidTransactions,

        isCreateModalOpen,
        setIsCreateModalOpen,
        createModalStep,
        setCreateModalStep,
        modalMode: modalMode,
        setModalMode,
        onModalClose,

        traitPickerMode,
        setTraitPickerMode,

        giveNfts,
        setGiveNfts,
        findTraitCollectionWBuySellForNfts,
        giveCollections,
        setGiveCollections,
        getCollections,
        setGetCollections,
        getTraitCollections,
        setGetTraitCollections,

        shouldRefresh,
        setShouldRefresh,
        pointsConfiguration,
        calculatedPointsConfiguration,
        setActiveTab,
        activeTab,
        getTraitCollectionIds,
        setGetTraitCollectionIds,
      }}
    >
      {children}
    </CollectionSwapsContext.Provider>
  )
}

// Hook to use the context
export const useCollectionSwaps = () => {
  const context = useContext(CollectionSwapsContext)
  if (context === undefined) {
    throw new Error('useCollectionSwaps must be used within a CollectionSwapsProvider')
  }
  return context
}
