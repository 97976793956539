import React, { useState, useMemo } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Flex, Box, Image, VStack, Text, Button, Switch, Checkbox, Input } from '@chakra-ui/react'
import { TraitCollectionData } from '../../../../types/collectionSwapV2'
import { SearchIcon, SmallCloseIcon } from '@chakra-ui/icons'
import CustomTooltip from '../../CustomTooltip'

const MAX_SELECTIONS = 12

type TraitPickerTableProps = {
    traitCollectionsData: TraitCollectionData[],
    selectedTraitIds: string[],  // Changed from selectedTraits
    selectGetTrait: (traitId: string) => void
}

const TraitPickerTable: React.FC<TraitPickerTableProps> = ({
    traitCollectionsData,
    selectedTraitIds,  // Changed from selectedTraits
    selectGetTrait
}) => {
    const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({})
    const [expandAll, setExpandAll] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const toggleSection = (section: string) => {
        setExpandedSections((prev) => {
            const newExpandedSections = { ...prev }
            newExpandedSections[section] = !prev[section]

            // Check if all sections are expanded after this toggle
            const allExpanded = Object.values(newExpandedSections).every(value => value)
            setExpandAll(allExpanded)

            return newExpandedSections
        })
    }

    const toggleExpandAll = () => {
        const newExpandAll = !expandAll
        setExpandAll(newExpandAll)
        const newExpandedSections: Record<string, boolean> = {}
        Object.keys(filteredTraitCategories).forEach((category) => {
            newExpandedSections[category] = newExpandAll
        })
        setExpandedSections(newExpandedSections)
    }

    const filteredTraitCategories = useMemo(() => {
        let categories: Record<string, TraitCollectionData[]> = {}
        traitCollectionsData.forEach((trait) => {
            if (!categories[trait.traitKey]) {
                categories[trait.traitKey] = []
            }
            if (
                trait.traitValue.toLowerCase().includes(searchTerm.toLowerCase()) ||
                trait.traitKey.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
                categories[trait.traitKey].push(trait)
            }
        })
        return categories
    }, [traitCollectionsData, searchTerm])


    return (
        <>
            <Box width="100%">
                <Text color=" white" fontSize="xl" fontWeight="bold" width="100%" id="trait-selection" mt={{ base: 4, md: 2 }}>Choose Desired Traits</Text>
            </Box>

            <Flex flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" alignItems={{ base: "stretch", md: "center" }} width="100%" borderRadius="md" p={2}>
                <Flex alignItems="center" width={{ base: "100%", md: "75%" }} mb={{ base: 4, md: 0 }}>
                    <div className="relative w-full">
                        <Input
                            type="text"
                            placeholder="Search traits..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full py-2 potato-radius new-bg-transparent"
                            style={{ paddingLeft: '30px', color: 'white' }}
                            _placeholder={{ color: 'gray.100' }}
                        />
                        <SearchIcon
                            color='white'
                            position="absolute"
                            left="10px"
                            top="50%"
                            transform="translateY(-50%)"
                            zIndex={2}
                            pointerEvents="none"
                        />
                        <SmallCloseIcon
                            color="white"
                            background="gray.300"
                            rounded="full"
                            _hover={{ bg: 'gray.400' }}
                            position="absolute"
                            right="10px"
                            top="50%"
                            transform="translateY(-50%)"
                            zIndex={2}
                            onClick={() => setSearchTerm('')}
                        />
                    </div>
                </Flex>
                <Flex alignItems="center" justifyContent={{ base: "flex-end", md: "flex-start" }}>
                    <Text color="white" fontSize="large" fontWeight="medium" mr={2}>Expand</Text>
                    <Switch size="lg"
                        sx={{
                            '.chakra-switch__track': {
                                bg: expandAll ? '#FC822F' : '#99D6E9',
                            },
                        }}
                        isChecked={expandAll} onChange={toggleExpandAll} />
                </Flex>
            </Flex>

            {Object.entries(filteredTraitCategories).map(([category, traits]) => {
                if (category.toLowerCase().includes('rarity')) return null

                if (expandedSections[category] === undefined) {
                    expandedSections[category] = false
                }
                const isExpanded = expandedSections[category] || expandAll


                return (
                    <Box key={category} borderRadius="md" overflow="hidden" width="100%">
                        <Button
                            className="potato-radius"
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={() => toggleSection(category)}
                            bg={isExpanded ? '#FC822F' : 'gray.200'}
                            _hover={{ bg: isExpanded ? '#E8640D' : 'gray.300' }}
                            fontSize="large"
                            fontWeight="bold"
                        >
                            <Text fontWeight="bold" color={isExpanded ? 'white' : 'gray.700'}>
                                {category} ({traits.length})
                            </Text>
                            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        </Button>
                        {isExpanded && (
                            <VStack align="stretch" mt={2} spacing={2} p={2} width="100%">
                                {traits.map((trait) => {
                                    const isSelected = selectedTraitIds.includes(trait.traitId)  // Changed
                                    const isMaxed = selectedTraitIds.length >= MAX_SELECTIONS  // Changed
                                    return (
                                        <CustomTooltip
                                            key={trait.traitId}
                                            label={isMaxed && !isSelected ? `Maximum ${MAX_SELECTIONS} selections allowed` : ''}
                                        >
                                            <Flex
                                                key={trait.traitId}
                                                borderRadius="full"
                                                className={isSelected ? 'trait-bg-transparent-selected' : 'trait-bg-transparent'}
                                                justifyContent="space-between"
                                                alignItems="center"
                                                p={2}
                                                width="100%"
                                                onClick={() => {
                                                    if (!isMaxed || isSelected) {
                                                        selectGetTrait(trait.traitId)
                                                    }
                                                }}
                                                cursor={isMaxed && !isSelected ? 'not-allowed' : 'pointer'}
                                                opacity={isMaxed && !isSelected ? 0.5 : 1}
                                            >
                                                <Flex alignItems="center" flex={1}>
                                                    <Image
                                                        src={trait.image}
                                                        alt={trait.traitKey + ': ' + trait.traitValue}
                                                        boxSize="45px"
                                                        mr={2}
                                                        borderRadius="full"
                                                        border="2px solid #a1a1a1"
                                                    />
                                                    <Text color={isSelected ? "#FF4ADA" : "white"} fontWeight="bold">
                                                        {trait.traitValue}
                                                    </Text>
                                                </Flex>
                                                <Flex justifyContent="flex-end" flex={1}>
                                                    <Text color={isSelected ? "#FF4ADA" : "white"} fontWeight="bold" mr={4}>
                                                        {((100 * trait.stats.count) / trait.totalNfts).toFixed(2)}%
                                                    </Text>
                                                    <Text color={isSelected ? "#FF4ADA" : "white"} fontWeight="bold" mr={2}>
                                                        {trait.stats.floorPrice ? (trait.stats.floorPrice / 10 ** 9).toFixed(2) : '--'} SOL
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </CustomTooltip>
                                    )
                                })}
                            </VStack>
                        )}
                    </Box>
                )
            })}
        </>
    )
}

export default TraitPickerTable

