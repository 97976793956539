import React from 'react'
import { Box, Text, BoxProps, Spinner } from '@chakra-ui/react'

interface PotateosBadgeProps extends Omit<BoxProps, 'children'> {
  points?: number
  isSelected?: boolean
  disableSelection?: boolean
  isSmall?: boolean
}

const PotateosBadge: React.FC<PotateosBadgeProps> = ({
  points,
  isSelected,
  disableSelection,
  isSmall,
  ...boxProps
}) => (
  <Box
    bg={isSelected ? '#008E20' : 'white'}
    border="1px solid #FC822F"
    borderRadius="full"
    style={{ opacity: disableSelection ? 0.8 : 1 }}
    {...boxProps}
  >
    <Text
      fontSize={['medium', 'medium', isSmall ? 'x-small' : 'medium']}
      fontWeight="medium"
      color={isSelected ? 'white' : 'gray.700'}
    >
      {points ? (
        `+ ${points} 🥔`
      ) : (
        <div>
          <Spinner size="sm" color="gray.700" />
          {`🥔`}
        </div>
      )}
    </Text>
  </Box>
)

export default PotateosBadge
